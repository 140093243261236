import AWS from 'aws-sdk';
import apigClientFactory from 'aws-api-gateway-client';
import { Logger } from 'aws-amplify';
import dayjs from 'dayjs';
import { logout } from '../auth-manager';
import { getSimpleRemoteConfig } from '../remote-config-manager';
import { enums, get, parseIntOrDefault } from '..';
import { AxiosError } from 'axios';
import * as dm from '../../data-models';

/*******************************************************************************
 * web-apis-client.js is a collection of functions that handle web calls
 ******************************************************************************/

const bootTime = dayjs();
const log = new Logger('web-apis-client');

/**
 * Create an APIGClient
 * @param invokeUrl URL to override for apigClient
 * @returns APIGClient
 */
export const createAPIClientForProfile = async (invokeUrl?: string | null) => {
  log.debug('createAPIClientForProfile', invokeUrl);
  const remoteConfig = await getSimpleRemoteConfig();
  return apigClientFactory.newClient({
    accessKey: AWS.config.credentials.accessKeyId,
    secretKey: AWS.config.credentials.secretAccessKey,
    sessionToken: AWS.config.credentials.sessionToken,
    invokeUrl: invokeUrl || remoteConfig['external_apis_invoke_url'],
    region: remoteConfig['external_apis_region'],
  });
};

/**
 * Check error for 403s and log out if detected to avoid further errors
 * @param error Error to check against
 * @returns void
 */
export const checkErrorForExpiredToken = async (error: Error | AxiosError) => {
  log.debug('checkError', error);
  const message = get(error, 'message', '');
  const response = get(error, 'response');
  const url = get(error, 'config.url', '');
  const data = get(error, 'config.data', '');

  if (
    message.indexOf('403') > -1 ||
    (message.toLowerCase() === 'network error' && response === undefined)
  ) {
    // ignore if it's amazon connect chat failing, it's flaky apparently
    // only check during the first minute of the app's startup to allow
    // for timeout functionality
    if (
      dayjs().isBefore(bootTime.add(1, 'minute')) &&
      ((url.includes('Prod/chat') && data.includes('ParticipantDetails')) ||
        url.includes('Prod/getIntercomConversations'))
    ) {
      return;
    }

    await logout();
  }
};

/**
 * Invoke the APIGClient invokeApi function with credential expiration checks
 * @param invokeUrl URL to override for invokeApi
 * @param pathParams Parameters to use in the path
 * @param pathTemplate String path
 * @param method String method
 * @param additionalParams Parameters to apply to the final invocation
 * @param body Object to pass as body
 * @returns Response
 */
export const invokeApiSafe = async (
  invokeUrl?: string | null,
  pathParams?: { [key: string]: string },
  pathTemplate?: string,
  method?: string,
  additionalParams?: { [key: string]: string },
  body?: any,
) => {
  try {
    const apigProfileClient = await createAPIClientForProfile(invokeUrl);
    log.debug('invokeSafe', pathTemplate, method);
    return await apigProfileClient.invokeApi.apply(null, [
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body,
    ]);
  } catch (e: any) {
    await checkErrorForExpiredToken(e);
    throw e;
  }
};

/**
 * Invoke the APIGClient invokeApi through the expiration-safe function
 * @param path String path
 * @param method String method
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @param body Object body for update methods
 * @returns Response
 */
export const invokeApi = async (
  path: string,
  method: string,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
  body: any = {},
) => {
  const additionalParams: { [key: string]: any } = {};

  if (headers) {
    additionalParams.headers = headers;
  }
  if (queryParams) {
    additionalParams.queryParams = queryParams;
  }

  const result = await invokeApiSafe(
    null,
    {},
    path,
    method,
    additionalParams,
    body,
  );

  return result.data;
};

/**
 * Invokes a GET method with params
 * @param path String path
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @returns Response
 */
export const getApi = async (
  path: string,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
) => invokeApi(path, 'GET', headers, queryParams);

/**
 * Invokes a POST method with params
 * @param path String path
 * @param body Object body for update methods
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @returns Response
 */
export const postApi = async (
  path: string,
  body?: any,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
) => invokeApi(path, 'POST', headers, queryParams, body);

/**
 * Invokes a PATCH method with params
 * @param path String path
 * @param body Object body for update methods
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @returns Response
 */
export const patchApi = async (
  path: string,
  body?: any,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
) => invokeApi(path, 'PATCH', headers, queryParams, body);

/**
 * Invokes a PUT method with params
 * @param path String path
 * @param body Object body for update methods
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @returns Response
 */
export const putApi = async (
  path: string,
  body?: any,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
) => invokeApi(path, 'PUT', headers, queryParams, body);

/**
 * Invokes a DELETE method with params
 * @param path String path
 * @param body Object body for update methods
 * @param headers Object to pass to invokeApi
 * @param queryParams URL query params
 * @returns Response
 */
export const deleteApi = async (
  path: string,
  body?: any,
  headers?: { [key: string]: string },
  queryParams?: { [key: string]: string },
) => invokeApi(path, 'DELETE', headers, queryParams, body);

// gets
export const getCases = async () =>
  getApi(enums.ApiPaths.cases) as Promise<dm.Cases>;
export const getDisability = async () =>
  getApi(enums.ApiPaths.disablity) as Promise<dm.Disability>;
export const getDocument = async (caseid: string, documentid: string) =>
  getApi(enums.ApiPaths.document, {
    caseid,
    documentid,
  });
export const getDocuments = async (caseid: string) =>
  getApi(enums.ApiPaths.documents, {
    caseid,
  }) as Promise<dm.Documents>;
export const getDraftClaims = async () =>
  getApi(enums.ApiPaths.draftClaims) as Promise<dm.DraftClaims>;
export const getLeaveScenarios = async () =>
  getApi(enums.ApiPaths.leaveScenarios); // "See LL Model"
export const getPaymentPreferences = async () =>
  getApi(enums.ApiPaths.getPaymentPreferences); // no model
export const getPerson = async () =>
  getApi(enums.ApiPaths.person) as Promise<dm.Person>;
export const getPersonData = async () =>
  getApi(enums.ApiPaths.personData) as Promise<dm.PersonData>;
export const getPreferences = async () =>
  getApi(enums.ApiPaths.preferences) as Promise<dm.CommunicationPreferences>;
export const getRefresh = async () => getApi(enums.ApiPaths.refresh);
export const getTasks = async (caseid: string) =>
  getApi(enums.ApiPaths.tasks, { caseid }) as Promise<dm.Tasks>;
export const getZelleEnrollmentsStatus = async (telephonenumber?: string) => {
  return getApi(
    enums.ApiPaths.getZelleEnrollmentsStatus,
    telephonenumber
      ? {
          telephonenumber,
        }
      : undefined,
  );
};

// posts
export const postRefresh = async () => postApi(enums.ApiPaths.refresh);
export const postCallback = async (body: { [key: string]: any }) =>
  postApi(enums.ApiPaths.callback, body);

export interface IPostDocumentArgs {
  fileName: string;
  contentType: string;
  actionType: string;
  taskKey?: string | number;
}

export interface IPostDocumentArgsWithCaseId extends IPostDocumentArgs {
  caseId: string;
  planId?: never;
}

export interface IPostDocumentArgsWithPlanId extends IPostDocumentArgs {
  caseId?: never;
  planId: string;
}

export const postDocument = async (
  args: IPostDocumentArgsWithCaseId | IPostDocumentArgsWithPlanId,
) => {
  const documentBody: any = {
    filename: args.fileName,
    contenttype: args.contentType,
    action: args.actionType,
    // we should have at least one of these at this point
    ...(args.caseId && { caseid: args.caseId }),
    ...(args.planId && { planid: args.planId }),
    // below are optional properties
    ...(args.taskKey && { taskkey: args.taskKey }),
  };

  return postApi(enums.ApiPaths.document, documentBody);
};

export const postPreferences = async (
  preferences: dm.CommunicationPreferences,
) => postApi(enums.ApiPaths.preferences, preferences);
export const postESignature = async (
  caseid: string,
  request: dm.EsignatureRequest,
) => postApi(enums.ApiPaths.esignature, request, { caseid });
export const postSubmission = async (
  planid: string,
  submissionESignatures: dm.SubmissionESignature,
) => postApi(enums.ApiPaths.submission, submissionESignatures, { planid });
export const postWebMessage = async (body: dm.Message, caseid?: string) =>
  postApi(enums.ApiPaths.webmessage, body, caseid ? { caseid } : undefined);
export const postDisability = async (body: dm.Disability) =>
  postApi(enums.ApiPaths.disablity, body);
export const postHospitalization = async (body: dm.Hospitalization) =>
  postApi(enums.ApiPaths.hospitalization, body);
export const postPregnancy = async (body: dm.Pregnancy) =>
  postApi(enums.ApiPaths.pregnancy, body);
export const postPaymentPreferences = async (body: any, id: string) =>
  postApi(enums.ApiPaths.setPaymentPreferences, body, undefined, {
    paymentPreferenceId: id,
  });
export const postChatDetails = async (body: any) =>
  postApi(enums.ApiPaths.chat, body);
export const postLDW = async (body: any, caseid: string) =>
  postApi(enums.ApiPaths.ldw, body, { caseid });
export const postDraftClaim = async (body: dm.DraftClaim) =>
  postApi(enums.ApiPaths.draftClaim, body);
export const postAbsences = async (caseId: string, body: dm.EpisodicAbsence) =>
  postApi(enums.ApiPaths.episodicAbsences, body, { caseid: caseId });

export const postADA = async (body: dm.AccommodationSubmission) =>
  postApi(enums.ApiPaths.ada, body);

// puts
export const putDraftClaim = async () => putApi(enums.ApiPaths.draftClaim);

//patches
export const patchTask = async (
  taskId: string | number,
  planId: string | undefined,
  caseId: string,
) => {
  const headers: any = {};
  if (planId) {
    headers.planId = planId;
  } else {
    headers.caseId = caseId;
  }

  return patchApi(enums.ApiPaths.task + `/${taskId}/complete`, {}, headers);
};

// deletes
export const deleteDraftClaim = async (planId: string) =>
  deleteApi(enums.ApiPaths.draftClaim, {}, { planId });

// orchestration handlers
export const handleRefresh = async () => {
  return new Promise(async (resolve, reject) => {
    const remoteConfig = await getSimpleRemoteConfig();
    const refreshCheckInterval = parseIntOrDefault(
      remoteConfig[enums.RemoteConfigKeys.refreshCheckIntervalMs],
      2000,
    );
    const refreshCheckCount = parseIntOrDefault(
      remoteConfig[enums.RemoteConfigKeys.refreshCheckCount],
      60,
    );
    let currentCheckCount = 0;

    try {
      await postRefresh();
    } catch (e) {
      log.error(e);
    }

    const refreshInterval = setInterval(async () => {
      try {
        const result = await getRefresh();

        log.info('refreshResult:', result, currentCheckCount);

        // check result status, only return if not "started"
        const rs = get(result, 'status', '');
        if (rs.length > 0 && rs !== enums.RefreshStatus.started) {
          resolve(rs);
          clearInterval(refreshInterval);
        }

        currentCheckCount++;

        if (currentCheckCount > refreshCheckCount) {
          reject('Timeout');
          clearInterval(refreshInterval);
        }
      } catch (e) {
        log.error(e);
        reject(e);
        clearInterval(refreshInterval);
      }
    }, refreshCheckInterval);
  });
};
