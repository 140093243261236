import { fonts, rem } from '../../style';
import dayjs from 'dayjs';
import { ElementModels } from '@kentico/kontent-delivery';
import { enums } from '../../utils';

/**
 * defaults
 */

export const defaults = {
  fontName: enums.FontNames.openSans,
  fontType: enums.FontTypes.regular,
  fontSize: 18,
  color: '#525252',
};

/**
 * helper functions
 */

export interface StyledTextProps {
  fontName?: enums.FontNames;
  fontType?: enums.FontTypes;
  fontSize?: number;
  color?: string;
  valid?: boolean;
}

export const getFont = (
  name: StyledTextProps['fontName'] = enums.FontNames.openSans,
  type: StyledTextProps['fontType'] = enums.FontTypes.regular,
) => fonts[name][type];

const ERROR_MSG = 'No formValueGetter provided';

export type FormValueGetter = (v: string) => string;

export const argsReduce = function (args: any[], reducer: any) {
  args = Array.from(args);
  args.pop(); // => options
  const first = args.shift();
  return args.reduce(reducer, first);
};

export const getInternalResult = (o: any) => {
  let v = '';

  if (o.fn) {
    v = o.fn() || '';
    if (v.length === 0) {
      v = o.fn(o.data?.root);
    }
  }

  return v;
};

export const getParams = (args: any[]) => {
  const options = args.pop();

  return {
    params: args,
    options,
  };
};

export interface IHelperOptions {
  formValueGetter?: (v: string) => string;
  allAssetReferences?: { [name: string]: ElementModels.AssetModel };
}

/**
 * Map for Handlebars registerHelper() functions
 *
 * You will always get an IHelperOptions as the first argument and the standard
 * registerHelper arguments after that.
 *
 * https://handlebarsjs.com/guide/expressions.html#helpers
 */
export const helperMap: {
  [name: string]: (ho: IHelperOptions, ...args: any[]) => string;
} = {
  ifDefined: (ho, param, options) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const v = ho.formValueGetter(param);
    const isDefined = !(v === undefined || v === null);
    return isDefined ? options.fn(this) : null;
  },
  omitBlankLines: (_ho, options) => options?.fn(this)?.replace(/[\n]+/g, '\n'),
  style: (_ho, o) => {
    const f = getFont(o.hash['fontName'], o.hash['fontType']);

    const finalCss = `
      font-family: ${f.fontFamily};
      font-weight: ${f.fontWeight};
      font-size: ${rem(o.hash['fontSize'] || defaults.fontSize)};
      color: ${o.hash['color'] || defaults.color};
    `;

    return `<span style="${finalCss}">${o.fn(o.data.root)}</span>`;
  },
  dateFormat: (_ho, ...args) => {
    // value
    const { params, options } = getParams(args);
    let v = getInternalResult(options);

    if (v.length === 0) {
      v = params[1];
    }

    return dayjs(params.length > 1 ? params[1] : v).format(params[0]);
  },
  addDays: (_ho, param, date) => {
    if (date?.length > 0) {
      return dayjs(date).add(param, 'day').toISOString();
    }

    return '';
  },
  calcRTWDate: (_ho, ...args) => {
    const { options: o } = getParams(args);
    let returnDate = dayjs().add(6, 'M').add(-1, 'd');

    if (o.hash['leaveAllAtOnce']) {
      returnDate = o.hash['isSpecificDate']
        ? dayjs(o.hash['RTWDate'])
        : dayjs(o.hash['startDate']).add(o.hash['RTWRange'], 'd');
    }

    return returnDate.format('YYYY-MM-DD');
  },
  json: (_ho, p) => JSON.stringify(p).replace(/"/g, '\\"'),
  stringify: (_ho, p) => JSON.stringify(p),
  assetUrlByFilename: (ho, ...args) => {
    if (!ho.allAssetReferences) {
      throw Error('No assetReferences provided');
    }

    const { options } = getParams(args);
    const fileName = getInternalResult(options);
    return ho.allAssetReferences[fileName]?.url;
  },
  assetDescByFilename: (ho, ...args) => {
    if (!ho.allAssetReferences) {
      throw Error('No assetReferences provided');
    }

    const { options } = getParams(args);
    const fileName = getInternalResult(options);
    return ho.allAssetReferences[fileName]?.description || '';
  },

  // to support #if conditionals
  eq: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a === b),
  ne: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a !== b),
  lt: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a < b),
  gt: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a > b),
  lte: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a <= b),
  gte: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a >= b),
  and: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a && b),
  or: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a || b),
};

export const registerSelf = (
  hb: any,
  formValueGetter?: IHelperOptions['formValueGetter'],
  allAssetReferences?: IHelperOptions['allAssetReferences'],
) => {
  for (const key of Object.keys(helperMap)) {
    hb.registerHelper(key, (...args: any[]) =>
      helperMap[key].apply(this, [
        { formValueGetter, allAssetReferences },
        ...args,
      ]),
    );
  }
};
