import crypto from 'crypto';
import { getter, setter } from '@progress/kendo-react-common';
import * as enums from './enums';
import marked from 'marked';
import { models } from './cms-client';
import {
  getDynamicContent,
  getSimpleRemoteConfig,
} from './remote-config-manager/index';
import { PersonData, Cases, IGeneratedPersonData } from '../data-models';
import { Logger } from '@aws-amplify/core';

const log = new Logger('utils');

/*******************************************************************************
 * utils/index.js is a collection of miscellaneous utility functions
 ******************************************************************************/

/**
 * Retrieves the value for a given object and path
 * @param {*} obj The object to search the path for
 * @param {string} path The path to the value to retrieve
 * @param {*} returnIfNotFound The value to return if the path is not found
 */
export const get = <R, T>(obj: R, path: string, returnIfNotFound?: T) => {
  const fn = getter(path);
  const r = fn(obj);

  if (r === null || r === undefined || r === '') {
    return returnIfNotFound;
  }

  return r;
};

/**
 * Sets the value for a given object and path
 * @param {*} obj The object to search the path for
 * @param {string} path The path to the value to retrieve
 * @param {*} value Value to set on the object
 * @returns {*} object Modified object
 */
export const set = <R, T>(obj: R, path: string, value: T) => {
  const setPath = setter(path);
  setPath(obj, value);

  return obj;
};

/**
 * Creates a shallow copy of the provided object and adds a prefix to the object values
 * @param {string} prefix The prefix
 * @param {object} obj The object
 * @returns A shallow copy of the obj with a prefix added to the obj values
 */
export const prefixObjectValues = (
  prefix: string | undefined,
  obj: { [name: string]: string },
) => {
  const shallowCopy = { ...obj };

  if (prefix) {
    Object.keys(obj).forEach((k) => {
      shallowCopy[k] = `${prefix}${obj[k]}`;
    });
  }

  return shallowCopy;
};

/**
 * Used in RelayState for 2-way JWT verification
 * @param {number} length of returned random string
 * @param {string} chars to use
 */
export const randomString = (length: number, characters: string) => {
  // following code from https://github.com/sindresorhus/crypto-random-string/blob/master/index.js

  // Generating entropy is faster than complex math operations, so we use the simplest way
  const characterCount = characters.length;
  const maxValidSelector =
    Math.floor(0x10000 / characterCount) * characterCount - 1; // Using values above this will ruin distribution when using modular division
  const entropyLength = 2 * Math.ceil(1.1 * length); // Generating a bit more than required so chances we need more than one pass will be really low
  let string = '';
  let stringLength = 0;

  while (stringLength < length) {
    // In case we had many bad values, which may happen for character sets of size above 0x8000 but close to it
    const entropy = crypto.randomBytes(entropyLength);
    let entropyPosition = 0;

    while (entropyPosition < entropyLength && stringLength < length) {
      const entropyValue = entropy.readUInt16LE(entropyPosition);
      entropyPosition += 2;
      if (entropyValue > maxValidSelector) {
        // Skip values which will ruin distribution when using modular division
        continue;
      }

      string += characters[entropyValue % characterCount];
      stringLength++;
    }
  }

  return string;
};

/**
 * Parse markdown to HTML
 * @param markdown string Markdown to parse
 * @returns object containing __html with parsed message
 */
export const getHtmlFromMarkdown = (markdown: string) => {
  const parser = marked.setOptions({ gfm: true, breaks: true });
  const parsedMessage = parser(markdown);
  return { __html: parsedMessage };
};

/**
 * Used for grabbing JWT from SAML parsing redirect
 * @name {string} token to extract
 * @url {string} defaults to current window.location.href
 */
export const getUrlParameterByName = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Trims the end / from a path (or just returns / if root path)
 * @param path string
 * @returns string
 */
export const trimPathEndSlash = (path: string): string => {
  if (path !== '/') {
    return path.replace(/\/+$/, '');
  }

  return path;
};

/**
 * Parse JSON or return a default value
 * @param json string JSON to parse
 * @param defaultValue any Used to default the return value if unable to parse
 * @returns any Object value parsed, or defaultValue if unable to parse
 */
export const parseJSONOrDefault = (
  json: string | undefined,
  defaultValue = {},
) => {
  let result;

  try {
    result = JSON.parse(json || JSON.stringify(defaultValue));
  } catch (e) {
    log.error('parseJSONOrDefault', e);
    result = defaultValue;
  }

  return result;
};

/**
 * Parse string as integer or return a default value
 * @param int string int to parse
 * @param defaultValue any Used to default the return value if unable to parse
 * @returns number parsed, or defaultValue if unable to parse
 */
export const parseIntOrDefault = (int: string, defaultValue = 0): number => {
  let result;

  try {
    result = parseInt(int) || defaultValue;
  } catch (e) {
    log.error('parseIntOrDefault', e);
    result = defaultValue;
  }

  return result;
};

/**
 * Function scrolls the app to the passed in top and left positions
 * @param x top position
 * @param y left position
 */
export const scrollApp = (x = 0, y = 0) => {
  setTimeout(() => {
    const app = document.getElementById('app-portal-container');
    app?.scrollTo({
      top: x,
      left: y,
      behavior: 'smooth',
    });
  }, 0);
};

const sortAscending = (a: any, b: any) => {
  const o1 = a.order;
  const o2 = b.order;

  return o1 < o2 ? 1 : -1;
};

/**
 * This function gets all dynamic content for the specified path and returns all the code for the specified location
 * @param path URL path dynamic content is being looked for
 * @param location the location on the page for the content
 * @returns the html markdown for the specified piece of dynamic content
 */
export const getContent = async (path: string, location: string) => {
  if (path && path.endsWith('/')) {
    path = path.substring(0, path.length - 1); //remove trailing forward slash if exists
  }

  const content = await getDynamicContent(path);
  let code = '';
  content?.items?.sort(sortAscending);
  content?.items?.forEach((item: models.ContentBlob) => {
    if (
      item.location &&
      item.code &&
      item.location.value[0].codename === location
    ) {
      code += item.code.value;
    }
  });

  return code;
};

/**
 * This function returns a boolean to specify whether payment prefs should be shown to the company code in personData
 * @param personData the person data object
 * @returns boolean
 */
export const showPaymentPrefsBasedOnEmployerId = async (
  personData: PersonData | undefined,
) => {
  const employerIdsKey = enums.RemoteConfigKeys.hidePrefsForId;
  const showPrefsKey = enums.RemoteConfigKeys.showPaymentPrefs;
  const config = await getSimpleRemoteConfig();
  const erIds = config[employerIdsKey] || '-1';
  const erId = personData?.person?.companyCode;
  const hideForId = erIds.split('|').includes(erId);
  const showPaymentPrefs = config[showPrefsKey];
  return showPaymentPrefs && !hideForId;
};

/**
 * Takes incoming cases(leaves/claims) and reverse sorts by caseId
 * @param cases object
 * @returns cases object
 */
export const sortCasesByCaseIdDescending = (cases?: Array<any>) => {
  let caseTypeToSort = cases || [];
  caseTypeToSort = caseTypeToSort.sort((a, b) => {
    if (!a.caseId || !b.caseId) {
      return 0;
    }
    if (a.caseId < b.caseId) {
      return 1;
    }
    if (a.caseId > b.caseId) {
      return -1;
    }
    return 0;
  });
  return caseTypeToSort;
};

/**
 * Takes incoming cases(leaves/claims)
 * Generates parent NTN structure w/ child leaves+claims, all reverse sorted by NTN/CaseID
 * @param cases object
 * @returns ntn object
 */
export const generateParentNTNsFromCases = (cases?: Cases) => {
  const allClaims = sortCasesByCaseIdDescending(cases?.claims || []);
  const allLeaves = sortCasesByCaseIdDescending(cases?.leaves || []);
  const uniqueNTNIds = [
    ...Array.from(
      new Set([...allClaims, ...allLeaves].map((item) => item.parentId)),
    ),
  ].sort((a, b) => {
    const aNTN = parseInt(a.replace('NTN-', ''))
      ? parseInt(a.replace('NTN-', ''))
      : 0;
    const bNTN = parseInt(b.replace('NTN-', ''))
      ? parseInt(b.replace('NTN-', ''))
      : 0;
    return bNTN - aNTN;
  });

  return uniqueNTNIds.map((str) => {
    const matchingClaims = allClaims.filter((c) => c.parentId === str) || [];
    const matchingLeaves = allLeaves.filter((c) => c.parentId === str) || [];

    const integrated =
      matchingClaims.length && matchingLeaves.length ? true : false;

    const caseEid =
      matchingLeaves[0] && matchingLeaves[0].eid
        ? matchingLeaves[0].eid
        : matchingClaims[0] && matchingClaims[0].eid
        ? matchingClaims[0].eid
        : '';

    return {
      NTNId: str,
      eid: caseEid,
      claims: matchingClaims,
      leaves: matchingLeaves,
      isIntegrated: integrated,
    };
  });
};

/**
 * Creates analytics data using personData
 * @param personData The personData object
 */
export const setAnalyticsData = (personData?: IGeneratedPersonData) => {
  if (!personData) {
    return;
  }

  const analytics = {
    customerNumber: '',
    employerId: '',
    employerName: '',
    firstTimeLogin: '',

    cases: undefined as any,
    documents: undefined as any,
    tasks: undefined as any,
  };

  if (personData.person) {
    analytics.customerNumber = (personData.person as any).customerNumber
      ? (personData.person as any).customerNumber
      : '';

    analytics.employerId = personData.person.companyCode
      ? personData.person.companyCode
      : '';

    analytics.employerName = personData.person.employerName
      ? personData.person.employerName
      : '';

    analytics.firstTimeLogin = personData.person.firstTimeLogin
      ? personData.person.firstTimeLogin
      : '';
  }

  analytics.cases = personData.cases ? personData.cases : {};
  analytics.documents = personData.documents ? personData.documents : {};
  analytics.tasks = personData.tasks ? personData.tasks : {};

  (window as any).caas = (window as any).caas ? (window as any).caas : {};
  (window as any).caas.analytics = analytics;
};

/**
 * Checks if a time duration is valid
 * @param duration a time duration string
 * @returns boolean
 */
export const timeDurationIsValid = (duration: string) => {
  if (!duration || !duration.split) {
    return true;
  }

  const durationParts = duration.split(':');
  const h = durationParts[0],
    m = durationParts[1];

  return (
    !duration.includes('_') &&
    ((parseInt(h) === 24 && parseInt(m) === 0) ||
      (parseInt(h) <= 23 && parseInt(m) <= 59))
  );
};

/**
 * Formats number as a condenseed byte phrase
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param bytes Number to format
 * @param decimals Count of decimal precision
 * @returns #.#MB
 */
export const formatBytes = (bytes: any, decimals = 1) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export interface LeaveTime {
  days: string;
  hours: string;
  minutes: string;
}

export const convertAvailableTimeBalance = (
  balance: number,
  basis: string,
): LeaveTime => {
  let days = '';
  let hours = '';
  let minutes = '';
  if (basis === 'Days') {
    days = Math.floor(balance).toString();
    const numHours = (balance - parseInt(days)) * 24;
    hours = Math.floor(numHours).toString();
    const numMinutes = (numHours - parseInt(hours)) * 60;
    minutes = Math.floor(numMinutes).toString();
  } else if (basis === 'Weeks') {
    days = Math.floor(balance * 7).toString();
    const numHours = (balance * 7 - parseInt(days)) * 24;
    hours = Math.floor(numHours).toString();
    const numMinutes = (numHours - parseInt(hours)) * 60;
    minutes = Math.floor(numMinutes).toString();
  }
  return {
    days,
    hours,
    minutes,
  };
};

export const referrerIsOdyssei = async () => {
  const referrer = document.referrer;
  const config = await getSimpleRemoteConfig();
  const loginUrl = config['login_redirect_url'];

  if (referrer === null || referrer === undefined || !referrer.includes('/')) {
    return false;
  }

  return referrer.split('/')[2] === loginUrl.split('/')[2];
};

export { enums };
