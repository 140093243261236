import error from '../../../../images/exception-screens/fatal-general.svg';
import PropTypes from 'prop-types';
import useDocumentTitle from '../../../../hooks/use-document-title';
import { ArrowIcon, ActionLinkButton } from '../../simple-styled-components';
import { ExceptionScreen } from '../exception-screen';
import { prefixObjectValues } from '../../../../utils';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  actionLink: 'error-screen-action-link',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * ErrorScreen component
 * @param {ErrorScreen.propTypes} props ErrorScreen propTypes
 ******************************************************************************/
const ErrorScreen = (props) => {
  useDocumentTitle('Unexpected Error');
  const domIds = domIdsUnique(props.keyId);

  return (
    <ExceptionScreen
      messageHeader={props.messageHeader}
      messageImageUrl={props.messageImageUrl}
      messageSubHeader={props.messageSubHeader}
    >
      <ActionLinkButton
        id={domIds.actionLink}
        onClick={() => window.location.reload()}
      >
        {props.actionText}
        <ArrowIcon />
      </ActionLinkButton>
    </ExceptionScreen>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
ErrorScreen.propTypes = {
  actionText: PropTypes.string,
  messageHeader: PropTypes.string.isRequired,
  messageImageUrl: PropTypes.string.isRequired,
  messageSubHeader: PropTypes.string.isRequired,
};

ErrorScreen.defaultProps = {
  actionText: 'Reload',
  messageHeader: 'Oh no, something went wrong',
  messageImageUrl: error,
  messageSubHeader: 'Please try again later',
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { ErrorScreen, domIdsUnique };
