import React from 'react';
import { Link } from 'react-router-dom';
import HtmlToReact from 'html-to-react';

//Instructions for the convertAnchorTagsToRouterLinks function. Exported for testing.
export const aTagInstruction = {
  replaceChildren: false,
  shouldProcessNode: (node: any) => {
    if (node.name === 'a') {
      const linkType = node.attribs['data-link-type'];

      return linkType && linkType === 'internal';
    }
    return false;
  },
  processNode: (node: any, children: any) => {
    const { href } = node.attribs;

    delete node.attribs.href;

    return React.createElement(Link, { to: href, ...node.attribs }, children);
  },
};

/**
 * Parses an html string to convert all anchor tags with the data-link-type attribute equal to 'internal' to Router links to prevent browser refresh.
 * Converts html to React DOM structure.
 *
 * @param content : string;
 */
export const convertAnchorTagsToRouterLinks = (content: string) => {
  const HtmlToReactParser = HtmlToReact.Parser;

  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  const defaultInstruction = {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  };

  const processingInstructions = [aTagInstruction, defaultInstruction];

  const htmlToReactParser = new HtmlToReactParser();

  return htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    processingInstructions,
  );
};
