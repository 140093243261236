import { getClient, models } from '../cms-client';
import envConfig from '../env-config';
import { localCache } from '../app-cache';
import { Logger } from 'aws-amplify';
import { enums } from '..';
import { ElementModels } from '@kentico/kontent-delivery';
import { IKenticoData } from '../../contexts/kentico-data-context';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

/*******************************************************************************
 * remote-config-manager.js is a collection of functions to assist with
 * fetching remote configuration and making it available to the app
 ******************************************************************************/

const envCodename = envConfig.upEnvironment.toLowerCase().replace('.', '_');
const log = new Logger('remote-config-manager');

/** Domain Models for Cache Storage */
export interface CallCenterDomainModel {
  allDay: boolean;
  start: Date | null;
  end: Date | null;
}

export interface IFeatureFlagMap {
  feedback: boolean;
  show_tasks: boolean;
  [name: string]: boolean;
}

export interface SplashConfig {
  showSplash: boolean;
  content?: string;
  nextStartDateTime?: Date;
}

export const DEFAULT_FEATURE_FLAGS: IFeatureFlagMap = {
  feedback: false,
  show_tasks: false,
};

/**
 * Gets a value in milliseconds that represents the current time plus the
 * provided number of minutes
 * @param {number} minutes The number of minutes
 * @returns {number} The current time plus the provided minutes (in ms)
 */
export const minutesInFuture = (minutes: number) =>
  new Date().getTime() + minutes * 60 * 1000;

/**
 * Gets a value in milliseconds that represents the current time plus the
 * provided number of minutes
 * @param {number} minutes The number of minutes
 * @returns {number} The current time plus the provided minutes (in ms)
 */
export const hoursInFuture = (hours: number) =>
  new Date().getTime() + hours * 60 * 60 * 1000;

/**
 * Retrieves asset config from Kentico, maps it, and caches it
 * @returns {object} asset configuration key/value pairs
 */
const getAllAssetReferences = async () => {
  log.debug('getAllAssetReferences...');

  const assetRefs: IKenticoData['assets'] = {};
  const result = await (await getClient())
    .items<models.AssetContainer>()
    .type('asset_container')
    .equalsFilter('system.codename', enums.CodeNames.allAssets)
    .toPromise();

  const firstItem = result.getFirstItem();

  if (!firstItem) {
    throw Error('Unable to get assets');
  }

  for (const asset of firstItem.assets.value) {
    assetRefs[asset.name] = asset;
  }

  return assetRefs;
};

/**
 * Retrieves call center closure config from Kentico
 * @param {Date} date to filter in greaterThanFilter()
 * @returns {object} form configuration key/value pairs
 */
const getCallCenterClosureConfig = async (endDate: Date) => {
  log.debug('getCallCenterClosureConfig...');

  const r = await (await getClient())
    .items<models.CallCenterClosure>()
    .type('call_center_closure')
    .greaterThanFilter('elements.end', endDate.toISOString())
    .toPromise();

  // build domain model to store in cache
  return r.items.map((i) => ({
    allDay: i.flags.value.some((f) => f.codename === enums.FormFlags.allDay),
    start: i.start.value,
    end: i.end.value,
  })) as CallCenterDomainModel[];
};

/**
 * Retrieves form config from Kentico
 * @returns {object} form configuration key/value pairs
 */
const getFormConfig = async (formName: string, depthParameter = 3) => {
  // TODO: create derived models using Kontent's custom models
  // https://github.com/Kentico/kontent-delivery-sdk-js/blob/master/DOCS.md#use-custom-models-for-custom-elements
  return (await getClient())
    .items<models.Form>()
    .type('form')
    .equalsFilter('elements.path_match', formName)
    .limitParameter(1)
    .depthParameter(depthParameter)
    .toPromise();
};

/**
 * Retrieves simple remote config from Kentico, maps it, and caches it
 * @returns {object} remote configuration key/value pairs
 */
const getSimpleRemoteConfig = async () => {
  log.debug('getSimpleRemoteConfig...');
  let remoteConfig = localCache.getItem(enums.CacheKeys.remote_config);

  if (!remoteConfig) {
    remoteConfig = {};
    const result = await (await getClient())
      .items()
      .type('simple_remote_configuration_item')
      .elementsParameter([envCodename])
      .toPromise();

    for (const item of result.items) {
      const codename = item.system.codename;
      remoteConfig[codename] = item[envCodename].value;
    }

    localCache.setItem(enums.CacheKeys.remote_config, remoteConfig, {
      expires: minutesInFuture(120),
    });
  }

  return remoteConfig;
};

/**
 * Retrieves feature flags from Kentico, maps it, and caches it
 * @returns {object} feature flag key/value pairs
 */
const getFeatureFlags = async () => {
  log.debug('getFeatureFlags...');

  const r = await (await getClient())
    .items<models.FeatureFlags>()
    .type('feature_flags')
    .elementsParameter([envCodename])
    .toPromise();

  const ff = DEFAULT_FEATURE_FLAGS;

  Object.keys(ff).forEach((k) => {
    ff[k] = r.items.some((i) =>
      i[envCodename].value.some(
        (f: ElementModels.MultipleChoiceOption) => f.codename === k,
      ),
    );
  });

  return ff;
};

/**
 * Retrieves dynamic content blobs from Kentico, maps it, and caches it
 * @returns {object} dynamic content blob
 */
const getDynamicContent = async (path: string) => {
  log.debug('getDynamicContent...');
  //
  let content = localCache.getItem(
    enums.CacheKeys.dynamicContent + path.replace(new RegExp(/\//g), '_'),
  );

  if (!content) {
    const result = await (await getClient())
      .items<models.ContentBlob>()
      .type('content_blob')
      .equalsFilter('elements.path', path)
      .toPromise();
    if (result.items.length > 0) {
      localCache.setItem(
        enums.CacheKeys.dynamicContent + path.replace(new RegExp(/\//g), '_'),
        result,
        {
          expires: minutesInFuture(5),
        },
      );
    }

    content = result;
  }

  return content;
};

/**
 * Gets splash configuration from Kentico, maps it, and returns it
 * @returns {ISplashConfig}
 */
const getSplashConfig = async (includeFuture = false) => {
  log.debug('getSplashConfig...');
  const splashConfig: SplashConfig = { showSplash: false };

  const resultA = (await getClient())
    .items<models.SplashConfig>()
    .type('splash_config')
    .containsFilter('elements.environments', [envCodename])
    .containsFilter('elements.recurring_splash', ['no']);

  const resultRecurring = (await getClient())
    .items<models.SplashConfig>()
    .type('splash_config')
    .containsFilter('elements.environments', [envCodename])
    .containsFilter('elements.recurring_splash', ['yes']);

  const resultB = includeFuture
    ? resultA.greaterThanFilter('elements.end_time', new Date().toISOString())
    : resultA
        .lessThanFilter('elements.start_time', new Date().toISOString())
        .greaterThanFilter('elements.end_time', new Date().toISOString());

  const result = await resultB.toPromise();
  const recurringSplashes = await resultRecurring.toPromise();
  let recurringSplash: SplashConfig = { showSplash: false };

  if (recurringSplashes.items.length) {
    recurringSplash = mapRecurringSplash(recurringSplashes);
  }

  if (result.items.length) {
    splashConfig.content = result.items[0].content.value;
    splashConfig.nextStartDateTime =
      result.items[0].startTime.value || new Date();
    splashConfig.showSplash = true;
  }

  if (recurringSplash.showSplash && !splashConfig.showSplash) {
    return recurringSplash;
  }

  return splashConfig;
};

//Assumes only a single recurring splash in action
const mapRecurringSplash = (recurringSplashes: any): SplashConfig => {
  const today = dayjs();
  const startDate = dayjs(recurringSplashes.items[0].startTime.value);
  const endDate = dayjs(recurringSplashes.items[0].endTime.value);

  startDate.set('year', today.year());
  startDate.set('month', today.month());
  startDate.set('date', today.date());
  endDate.set('year', today.year());
  endDate.set('month', today.month());
  endDate.set('date', today.date());

  return {
    content: recurringSplashes.items[0].content.value,
    nextStartDateTime: recurringSplashes.items[0].startTime.value || new Date(),
    showSplash: today.isBetween(startDate, endDate),
  };
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export {
  getCallCenterClosureConfig,
  getAllAssetReferences,
  getFormConfig,
  getSimpleRemoteConfig,
  getSplashConfig,
  getFeatureFlags,
  getDynamicContent,
};
