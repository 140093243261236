import { ICache } from '@aws-amplify/cache/lib-esm/types';
import { Cache } from 'aws-amplify';

/*******************************************************************************
 * app-cache.js exposes session and local storage caches and the keys to the
 * items in the caches
 ******************************************************************************/

/**
 * The keyprefix to use for the caches
 */
const keyPrefix = 'claimant-portal-';

/**
 * A localStorage cache for the application
 */
const localCache: ICache = Cache.createInstance({
  keyPrefix,
});

/**
 * A sessionStorage cache for the application
 */
// eslint-disable ts2742
const sessionCache: ICache = Cache.createInstance({
  keyPrefix,
  storage: window.sessionStorage,
});

/**
 * Helper function to clear both local and session cache
 */
const clearCache = () => {
  localCache.clear();
  sessionCache.clear();
};

/**
 * Clear both local and session cache and reload
 */
const clearCacheAndReload = () => {
  clearCache();
  window.location.reload();
};

/**
 * Removes an item from the cache and returns its value
 * @param {import('aws-amplify').Cache} cacheObj The cache object to pop the item from
 * @param {string} key The key of the cached item
 */
const popCacheItem = (cacheObj: any, key: string) => {
  const rtn = cacheObj.getItem(key);
  cacheObj.removeItem(key);
  return rtn;
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export {
  clearCache,
  clearCacheAndReload,
  localCache,
  popCacheItem,
  sessionCache,
};
