import styled from 'styled-components';
import { fonts } from '../../../style';

const TABLET = 980;

const MOBILE = 765;

export const Row = styled.div<{ reverseMobile?: boolean }>`
  flex-direction: row;
  display: flex;
  max-height: inherit;
  @media (max-width: ${TABLET}px) {
    flex-direction: ${(p) => (p.reverseMobile ? 'column-reverse' : 'column')};
  }
  @media (min-width: ${MOBILE}px) {
    height: 100%;
  }
`;

export const Banner = styled.div<{ backgroundColor: string }>`
  flex-direction: row;
  display: flex;
  justify-content: center;
  background-color: ${(p) => p.backgroundColor};
  color: white;
`;

export const BannerText = styled.p`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: 16px;
  margin: 20px 0px;
  max-width: 70%;
  color: white;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex: 1 1 0px;
  height: 100%;

  @media (max-width: ${MOBILE}px) {
    flex-direction: column;
  }
`;

export const WhiteStrip = styled(Column)`
  @media (min-width: ${MOBILE}px) {
    padding-bottom: 70px;
  }

  @media (min-width: ${TABLET}px) {
    display: block;
    justify-content: space-evenly;
    padding-bottom: 0;
  }

  @media (max-width: ${TABLET}px) {
    flex-direction: row;
    justify-content: start;
  }
`;

export const BlueStrip = styled(Column)<{ main?: boolean }>`
  @media (min-width: ${TABLET}px) {
    display: ${(p) => (p.main ? 'block' : 'flex')};
    justify-content: space-evenly;
  }

  @media (max-width: ${TABLET}px) {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;
  }
`;

export const MainContentContainer = styled.div`
  @media (min-width: ${TABLET}px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
`;

export const Title = styled.h1<{ main?: boolean }>`
  font-family: ${fonts.openSans.light.fontFamily};
  font-weight: ${fonts.openSans.light.fontWeight};
  font-size: 44px;
  color: #335e7a;
  margin-bottom: ${(p) => (p.main ? '38px' : '2px')};
  max-width: 400px;

  @media (max-width: ${TABLET}px) {
    font-size: 40px;
  }
`;

export const SecondaryTitle = styled.h2`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 22px;
  color: #fff;
`;

export const Paragraph = styled.p<{ small?: boolean; whiteContrast?: boolean }>`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: ${(p) => (p.small ? '14px' : '16px')};
  margin: 0;
  max-width: 500px;
  color: ${(p) => (p.whiteContrast ? '#fff' : '#2e2e2e')};

  @media (max-width: ${TABLET}px) {
    max-width: ${(p) => (p.whiteContrast ? '100%' : '500px')};
  }
`;

export const CustomButton = styled.a`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 16px;
  color: #017f90;
  background-color: #f6f8fa;
  border: 1px solid #017f90;
  border-radius: 30px;
  min-width: 226px;
  padding: 12px 24px;
  margin-right: 10px;
  text-decoration: none;

  &:hover {
    background-color: #0199a6;
    color: #fff;
    text-decoration: none;
  }
`;

export const LoginButton = styled(CustomButton)`
  color: #fff;
  background-color: #017f90;

  @media (max-width: 1520px) {
    margin: 25px 0px;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${TABLET}px) {
    position: relative;
    display: flex;
    top: 0;
    left: 0;
    transform: none;
    max-height: 445px;
    align-items: end;
    margin-top: 50px;
    padding-right: 10px;
  }

  @media (max-width: ${MOBILE}px) {
    display: none;
  }
`;

export const MobileImage = styled.img`
  display: none;
  max-width: 321px;
  height: auto;
  margin-left: 5%;

  @media (max-width: ${MOBILE}px) {
    display: flex;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 60px;
  margin-top: 40px;
  padding-left: 10%;
  @media (max-width: ${MOBILE}px) {
    padding-left: 5%;
  }
`;

export const VerticalAlignedRow = styled.div<{ copyright?: boolean }>`
  background-color: ${(p) => (p.copyright ? '#ecf2f5' : 'initial')};
  padding-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;

  @media (min-width: 1520px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TextContainer = styled.div`
  max-width: 540px;
  margin-right: 5%;

  @media (max-width: ${MOBILE}px) {
    margin-left: 5%;
  }
`;

export const MainTextContainer = styled(TextContainer)`
  margin-left: 10%;

  @media (max-width: ${MOBILE}px) {
    padding-top: 0;
  }

  @media (min-width: ${TABLET}px) {
    margin-right: 192px;
    align-self: flex-end;
  }

  @media (min-width: 1520px) {
    margin-right: 192px;
  }
`;

export const SecondaryTextContainer = styled(TextContainer)`
  margin-left: 210px;

  @media (max-width: ${TABLET}px) {
    padding-top: 41px;
    margin-left: 54px;
    max-width: 100%;
  }

  @media (max-width: ${MOBILE}px) {
    padding-top: 41px;
    margin-left: 5%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  > img {
    margin-right: 15px;
  }
`;

export const CopyRightContainer = styled.div<{ main?: boolean }>`
  display: ${(p) => (p.main ? 'flex' : 'none')};
  bottom: 0;
  margin-left: 10%;
  margin-right: 5%;
  margin-bottom: 42px;
  align-items: end;

  @media (max-width: ${TABLET}px) {
    display: ${(p) => (p.main ? 'none' : 'flex')};
    margin-left: 54px;
  }

  @media (max-width: ${MOBILE}px) {
    margin-top: 40px;
    margin-left: 10%;
  }

  > p {
    @media (max-width: ${TABLET}px) {
      color: #fff;
    }
  }
`;

export const CopyRightImage = styled.div<{
  desktopImage: string;
  mobileImage: string;
}>`
  min-width: 40px;
  height: 40px;
  margin-right: 15px;
  background: ${(p) => `url(${p.desktopImage}) center no-repeat`};

  @media (max-width: ${TABLET}px) {
    background: ${(p) => `url(${p.mobileImage}) center no-repeat`};
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media (max-width: ${TABLET}px) {
    flex-direction: row;
    > :last-child {
      margin-left: 90px;
    }
  }

  @media (max-width: ${MOBILE}px) {
    flex-direction: column;
    > :last-child {
      margin-left: 0;
    }
  }
`;

export const SpanishContainer = styled.div`
  display: block;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const SpanishLinkContainer = styled.div`
  background: #eff3f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 14px;
  padding: 16px;
`;

export const SpanishInfoContainer = styled.div`
  background: #eff3f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: 14px;
  padding: 16px;
  width: 100%;
  max-width: 500px;
  margin-top: 1px;
`;

export const SpanishShowButton = styled.button`
  background: none !important;
  border: none;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 14px;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`;
