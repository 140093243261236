import { useEffect } from 'react';

/*******************************************************************************
 * Sets the document.title property
 * @param {string} title
 ******************************************************************************/
const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default useDocumentTitle;
