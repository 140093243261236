import { css } from 'styled-components';

/*******************************************************************************
 * style-props.js is a collection of common style properties that can be used
 * with styled-components or custom css
 ******************************************************************************/

const colors = {
  background: '#f7f9fb',
  backgroundAlt: '#DAE4E9',

  blue: '#015294',
  blueL1: '#B9D4E9',
  blueL2: '#78A5D1',
  blueL3: '#3273AF',
  blueD1: '#004470',

  slate: '#5E88A1',
  slateL1: '#AEC7D8',
  slateL2: '#84A9BF',
  slateD1: '#335E7A',
  slateD2: '#26495F',

  sky: '#00A6D7',
  skyL1: '#A1D3EA',
  skyL2: '#60BDE3',
  skyD1: '#0090C7',
  skyD2: '#037CB7',

  pool: '#47B4BC',
  poolL1: '#BBE0E1',
  poolL2: '#7CC6CA',
  poolD1: '#0199A6',
  poolD2: '#017F90',

  lawn: '#8CC342',
  lawnL1: '#B6D991',
  lawnD1: '#5FA143',
  lawnD2: '#318040',
  lawnD3: '#0A6A36',

  yellowGreen: '#C0CF30',
  yellowGreenL1: '#E0E7A2',
  yellowGreenL2: '#D3DB6A',
  yellowGreenD1: '#A4B638',
  yellowGreenD2: '#899D3B',

  orange: '#E99625',
  orangeL1: '#FAD375',
  orangeL2: '#F3B13B',
  orangeD1: '#D67921',
  orangeD2: '#C76428',

  gold: '#FAC832',

  warmNeutral: '#2E2E2E',
  warmNeutralL1: '#F7F7F7',
  warmNeutralL2: '#CCCCCC',
  warmNeutralL3: '#525252',

  coolNeutral: '#DFE7EC',
  coolNeutralL1: '#F6F8FA',
  coolNeutralL2: '#FFFFFF',
  coolNeutralL3: '#EAEEF1',
  coolNeutralD1: '#8993A4',
  coolneutrald2: '#EFF3F5',

  // additional non-standard common colors
  alabaster: '#f8f8f8',
  alabaster1: '#FCFCFC',
  alto: '#cfcfcf',
  apple: '#5e9e42',
  aquaSpring: '#f8fbfd',
  aquaHaze: '#eef2f5',
  bermuda: '#83bfd9',
  blackSqueeze: '#f5f9fb',
  boulder: '#7a7a7a',
  boulder1: '#757575',
  cancelRed: '#B70303',
  catskillWhite: '#e6eef4',
  earlyDawn: '#fffaeb',
  errorRed: '#b30303',
  heather: '#BDC9D5',
  potpourri: '#f7e6e6',
  rumSwizzle: '#f7f9e6',
  silver: '#cdcdcd',
  silverChalice: '#aaaaaa',
  torchRed: '#ff0e19',
  waikawaGray: '#597897',
  bubbleShadow: '#bbb',
  inputGray: '#b9b9b9',
  switchOff: '#bac5ce',
  lightBlueGray: '#CEE0E3',
  primaryBlue: '#007CB7',
  white: '#fff',
  sirocco: '#657676',
  geyser: '#dee2e6',
  strengthTextColor: '#484848',
  geyser2: '#D0DCE3',
  lightgray: '#D3D3D3',
  dustygray: '#979797',
  mineshaft: '#333333',
  persiangreen: '#00909b',
  alabaster2: '#FAFAFA',
  alto2: '#DDDDDD',
  prussianblue: '#013249',
  dovegray: '#666666',
  gray: '#929292',
  iceberg: '#d9f2ea',
  observatory: '#04885e',
  observatory1: '#04865D',
  sanmarino: '#4277A1',
  sanmarino2: '#4378A3',
  carrotorange: '#F29423',
  linkwater: '#D9EBF4',
  greenHaze: '#04A874',
  denim: '#127CB7',
  greenHaze1: '#04A975',
  christine: '#E1710E',
  cardinal: '#CD2331',
  bridesmaid: '#FEF3EA',
  wepeep: '#F8DEE0',
};

/**
 * Custom font properties for use with styled-components
 */
const fonts = {
  openSans: {
    bold: {
      fontFamily: `'Open Sans', 'Open Sans Bold', 'OpenSans-Bold', 'sans-serif'`,
      fontWeight: 700,
    },
    semibold: {
      fontFamily: `'Open Sans', 'Open Sans SemiBold', 'OpenSans-SemiBold', 'sans-serif'`,
      fontWeight: 600,
    },
    medium: {
      fontFamily: `'Open Sans', 'Open Sans SemiBold', 'OpenSans-Regular', 'sans-serif'`,
      fontWeight: 500,
    },
    regular: {
      fontFamily: `'Open Sans', 'Open Sans Regular', 'OpenSans-Regular', 'sans-serif'`,
      fontWeight: 400,
    },
    light: {
      fontFamily: `'Open Sans', 'Open Sans Light', 'OpenSans-Light', 'sans-serif'`,
      fontWeight: 300,
    },
  },
  ubuntu: {
    bold: {
      fontFamily: `'Ubuntu', 'Ubuntu Bold', 'sans-serif'`,
      fontWeight: 700,
    },
    semibold: {
      fontFamily: `'Ubuntu', 'Ubuntu SemiBold', 'sans-serif'`,
      fontWeight: 600,
    },
    medium: {
      fontFamily: `'Ubuntu', 'Ubuntu Regular', 'sans-serif'`,
      fontWeight: 500,
    },
    regular: {
      fontFamily: `'Ubuntu', 'Ubuntu Regular', 'sans-serif'`,
      fontWeight: 400,
    },
    light: {
      fontFamily: `'Ubuntu', 'Ubuntu Light', 'sans-serif'`,
      fontWeight: 300,
    },
  },
};

/**
 * Media query width sizes
 */
const mediaWidths = {
  giant: 1280,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

// iterate through the sizes and create a media template
// https://github.com/styled-components/styled-components/blob/master/packages/styled-components/docs/tips-and-tricks.md#media-templates
const maxWidthQuery = Object.keys(mediaWidths).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${mediaWidths[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

// iterate through the sizes and create a media template
// https://github.com/styled-components/styled-components/blob/master/packages/styled-components/docs/tips-and-tricks.md#media-templates
const minWidthQuery = Object.keys(mediaWidths).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${mediaWidths[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

/**
 * Converts pixels to root ems
 * @param {number} pixels - The number of pixels to convert
 * @returns {string} A rem css property value (e.g. 1.3rem)
 */
const rem = (pixels) => {
  // return 0 if 0 was passed
  if (pixels === 0) {
    return '0';
  }

  // defaulting to 16px as the base px value
  return `${pixels / 16}rem`;
};

const openSansSemiBoldStyle = css`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
`;

const tooltipStyle = css`
  padding: 6px 12px;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(13)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  border-color: ${colors.waikawaGray};
  background-color: ${colors.waikawaGray};
  border-radius: 4px;
`;

/**
 * Contains z-index constants
 */
const zIndices = {
  modal: 1000,
};

/***************************************************************************************
 * exported api
 **************************************************************************************/
export {
  fonts,
  colors,
  maxWidthQuery,
  minWidthQuery,
  openSansSemiBoldStyle,
  rem,
  tooltipStyle,
  zIndices,
};
