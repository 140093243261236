import config from './env-config.json';

/*******************************************************************************
 * env-config.js includes environment configuration for the app
 ******************************************************************************/

/**
 * @type {EnvironmentConfig}
 */
const envConfig = config[window.location.hostname];
envConfig.isProd = envConfig.upEnvironment === 'PRODUCTION';

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export default envConfig;
