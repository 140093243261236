/* eslint-disable import/first */
import * as AWS from 'aws-sdk';
declare module 'aws-sdk' {
  class Credentials {
    identityId: string;
  }
  class Config {
    credentials: Credentials;
  }
}
import axios from 'axios';
import apigClientFactory from 'aws-api-gateway-client';
import { sessionCache } from '../app-cache';
import { Logger } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { getSimpleRemoteConfig } from '../remote-config-manager';
import { get, set, enums, randomString, getUrlParameterByName } from '../';
import 'amazon-connect-chatjs';
import dayjs from 'dayjs';

const contentType = 'Content-Type';
declare global {
  namespace NodeJS {
    interface Global {
      document: Document;
      window: Window;
      navigator: Navigator;
      connectSession?: connect.CustomerChatSession | null;
      displayLiveChat: any;
    }
  }
}

/*******************************************************************************
 * auth-manager.js is a collection of functions to assist with
 * authentication and storage/retrieval of local user info and access tokens.
 * The application uses SAML from Forgerock to assume AWS temporary credentials
 ******************************************************************************/

const log = new Logger('auth-manager');

/**
 * Sets the AWS.config.credentials to the object provided
 * as well as the enums.CacheKeys.sessionCredentials sessionCache item
 * @param {object} creds Object containing accessKeyId, secretAccessKey, and sessionToken keys
 */
export const setAWSCredentials = (creds: AWS.Credentials) => {
  AWS.config.credentials.accessKeyId = creds.accessKeyId;
  AWS.config.credentials.secretAccessKey = creds.secretAccessKey;
  AWS.config.credentials.sessionToken = creds.sessionToken;
  sessionCache.setItem(
    enums.CacheKeys.sessionCredentials,
    JSON.stringify({
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    }),
  );
};

/**
 * Clears stored credentials
 */
export const clearAWSCredentials = () => {
  set(AWS, enums.CredentialPath.accessKeyId, null);
  set(AWS, enums.CredentialPath.secretAccessKey, null);
  set(AWS, enums.CredentialPath.sessionToken, null);
  sessionCache.removeItem(enums.CacheKeys.sessionCredentials);
  sessionCache.removeItem(enums.CacheKeys.userInfo);
};

/**
 * Get SAML credentials from the SAMLResponse
 * @param {string} samlResponse SAML response
 * @param {string} identityId generated value
 */
export const getSamlCredentials = async (
  jwt?: string | null,
  identityId?: string | null,
) => {
  const remoteConfig = await getSimpleRemoteConfig();
  const apigClient = apigClientFactory.newClient({
    invokeUrl: remoteConfig['cognito_invoke_url'],
    region: remoteConfig['cognito_region'],
  });

  const body = {};
  const additionalParams = {
    headers: {
      Authorization: jwt,
      COGNITO_ID: identityId,
    },
  };

  const response = await apigClient.invokeApi(
    {},
    '/saml',
    'GET',
    additionalParams,
    body,
  );
  const creds = get(
    response,
    'data.Attributes.credentials.M.Credentials.M',
    {},
  );

  const email = get(response, 'data.Attributes.userInfo.M.emailAddress.S', '');
  const firstName = get(response, 'data.Attributes.userInfo.M.firstName.S', '');
  const lastName = get(response, 'data.Attributes.userInfo.M.lastName.S', '');
  const eid = get(response, 'data.Attributes.credentials.M.Subject.S', '');

  const userInfo = {
    email,
    firstName,
    lastName,
    eid,
  };

  Hub.dispatch(enums.HubChannels.userInfo, {
    event: enums.HubEvents.init,
    data: userInfo,
  });

  sessionCache.setItem(enums.CacheKeys.userInfo, JSON.stringify(userInfo));

  setAWSCredentials(
    new AWS.Credentials({
      accessKeyId: get(creds, 'AccessKeyId.S', null),
      secretAccessKey: get(creds, 'SecretAccessKey.S', null),
      sessionToken: get(creds, 'SessionToken.S', null),
    }),
  );

  localStorage.setItem(enums.CacheKeys.lastLoginTime, dayjs().toISOString());
};

/**
 * Performs a check against the last login time
 * and logs out if greater than configured time
 */
export const checkLastLogin = async () => {
  const remoteConfig = await getSimpleRemoteConfig();
  const sessionDurationHours = parseInt(
    remoteConfig[enums.RemoteConfigKeys.awsSessionDurationHours],
  );
  const lastLoginTime = localStorage.getItem(enums.CacheKeys.lastLoginTime);

  log.info('checkLastLogin::start', sessionDurationHours, lastLoginTime);

  if (lastLoginTime) {
    const expireDatetime = dayjs(lastLoginTime).add(sessionDurationHours, 'h');
    const isExpired = dayjs().isAfter(expireDatetime);
    log.info('checkLastLogin::isExpired', isExpired, expireDatetime);

    if (isExpired) {
      await logout();
    }
  }
};

/**
 * Begin login workflow
 * @param {string} identityId generated value
 */
export const loginWorkflow = async (identityId?: string | null) => {
  const remoteConfig = await getSimpleRemoteConfig();
  const activelogin = sessionStorage.getItem(enums.CacheKeys.activelogin);

  if (activelogin === 'inProgress') {
    // ForgeRock login redirect from API Gateway
    const urlParams = new URLSearchParams(window.location.search);
    const jwt = urlParams.get('jwt');
    sessionStorage.removeItem(enums.CacheKeys.activelogin);

    // get and set AWS credentials generated from Forgerock SAML response
    await getSamlCredentials(jwt, identityId);

    // clean up browser URL
    window.history.replaceState(null, '', '/app');

    return true;
  } else {
    const sessionCredentialsString = sessionCache.getItem(
      enums.CacheKeys.sessionCredentials,
    );

    if (sessionCredentialsString != null) {
      // we have temporary credentials in session storage, use them
      const sessionCredentials = JSON.parse(sessionCredentialsString);
      setAWSCredentials(sessionCredentials);

      return true;
    } else {
      // only check last login if we've not been given the directive to login
      if (getUrlParameterByName('login') !== 'true') {
        // if they're loading from a bookmark perhaps after some time has passed, check lastLoginTime to determine if we need to log out
        await checkLastLogin();
      }

      // first page visit. Redirect to ForgeRock login.
      const RPID = encodeURIComponent(remoteConfig['cognito_relay_party_id']);

      const rString = randomString(
        32,
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      );
      const RelayState = encodeURIComponent(`${identityId}##${rString}`);
      const unencoded = `RPID=${RPID}&RelayState=${RelayState}`;
      const result = `${
        remoteConfig['cognito_idp_url']
      }&RelayState=${encodeURIComponent(unencoded)}`;

      sessionStorage.setItem(enums.CacheKeys.activelogin, 'inProgress');
      localStorage.removeItem(enums.CacheKeys.lastLoginTime);
      setTimeout(function () {
        window.location.assign(result);
      }, 100);
    }

    return false;
  }
};

export const forgerockLogout = async () => {
  const remoteConfig = await getSimpleRemoteConfig();
  const forgerockUrl = remoteConfig['forgerock_single_logout_url'];

  const response = await axios.request({
    method: 'post',
    withCredentials: true,
    url: forgerockUrl,
    headers: {
      [contentType]: 'text/plain',
    },
    data: '',
  });
  log.debug(response);
};

export const logout = async (redirect = true) => {
  const remoteConfig = await getSimpleRemoteConfig();
  sessionCache.removeItem(enums.CacheKeys.ssoCookieKey);
  sessionCache.removeItem(enums.CacheKeys.ssoCookieValue);

  try {
    await forgerockLogout();
    await global?.connectSession?.disconnectParticipant();
  } catch (e) {
    log.error(e);
  }

  clearAWSCredentials();

  if (redirect) {
    localStorage.removeItem(enums.CacheKeys.lastLoginTime);
    window.location.assign(remoteConfig['login_redirect_url']);
  }
};

/**
 * Fetches or generates the identityId from AWS
 */
export const fetchIdentityId = async () => {
  const remoteConfig = await getSimpleRemoteConfig();
  // when the page loads initially, load up all the security and ensure we're logged in
  const identityPool = remoteConfig['cognito_identity_pool'];
  AWS.config.region = remoteConfig['cognito_region'];

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPool,
  });

  const identityId = localStorage.getItem(enums.CacheKeys.cognitoid);

  if (identityId === null) {
    await AWS.config.credentials.getPromise();

    localStorage.setItem(
      enums.CacheKeys.cognitoid,
      AWS.config.credentials.identityId,
    );
  }

  return localStorage.getItem(enums.CacheKeys.cognitoid);
};

/**
 * Indicates whether session authentication data exists
 * @returns {boolean} Indicating that session authentication data exists
 */
export const hasAuthSessionData = () => {
  return sessionCache.getItem(enums.CacheKeys.sessionCredentials) !== null;
};

export const ssoCookieExists = async () => {
  const remoteConfig = await getSimpleRemoteConfig();
  try {
    const cookies = document.cookie.split(';');
    const cookieName = remoteConfig('sso_cookie_name');
    const ssoCookie =
      cookies.find((c) =>
        c.toLowerCase().includes(`${cookieName.toLowerCase()}=`),
      ) ?? '=';
    const ssoCookieValues = ssoCookie.split('=');
    return ssoCookieValues[1].trim().length > 0;
  } catch (_e) {
    // ignore failures and return blank
    return false;
  }
};

/**
 * Check if the user appears to be logged in
 * @returns {boolean}
 */
export const isAuthenticated = async () => {
  // handle immediate redirect due to IdP-initiated login
  const loginUrlParameter = getUrlParameterByName('login');
  const activelogin = sessionStorage.getItem(enums.CacheKeys.activelogin);
  const cookieExists = await ssoCookieExists();
  let triedLogin = false;

  // if we're coming back from APIGW with a jwt, give execute a chance to set AWS variables before continuing
  // if we don't have a jwt but do have a login directive, do that
  // or if we've previously logged in, use those credentials
  if (
    activelogin === 'inProgress' ||
    loginUrlParameter === 'true' ||
    hasAuthSessionData() ||
    cookieExists
  ) {
    const identityId = await fetchIdentityId();
    await loginWorkflow(identityId);
    triedLogin = true;
  }

  const awsValuesDefined =
    get(AWS, 'config.credentials.accessKeyId', '').length > 0 &&
    get(AWS, 'config.credentials.secretAccessKey', '').length > 0 &&
    get(AWS, 'config.credentials.sessionToken', '').length > 0;

  const cognitoIdDefined =
    localStorage.getItem(enums.CacheKeys.cognitoid) !== null;

  // if we have no indication that login should start or they could be refreshing
  // with credentials, they could be coming in from a bookmark, in which case reset
  // lastLoginTime and go to the login so they can continue from there.
  if (!triedLogin && (!cognitoIdDefined || !awsValuesDefined)) {
    await logout();
  }

  return awsValuesDefined && cognitoIdDefined;
};
