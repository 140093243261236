import React from 'react';
import { ElementModels } from '@kentico/kontent-delivery';
import {
  CallCenterDomainModel,
  getAllAssetReferences,
  getCallCenterClosureConfig,
  getFeatureFlags,
  getSimpleRemoteConfig,
  IFeatureFlagMap,
} from '../../utils/remote-config-manager';
import dayjs from 'dayjs';
import { parseJSONOrDefault } from '../../utils';
import { KenticoDataKeys } from '../../utils/enums';

export const defaults = {
  callCenterHoursOfOperation: {
    start: '2020-01-01T12:00:00.000Z',
    end: '2020-01-01T21:00:00.000Z',
  },
};

export interface IKenticoData {
  assets: {
    [name: string]: ElementModels.AssetModel;
  };
  callCenter: {
    closures: CallCenterDomainModel[];
    hoursOfOperation: { start: string; end: string };
  };
  featureFlags: IFeatureFlagMap;
}

export interface IKenticoDataProviderProps {
  children: any;
  kenticoContext: IKenticoData;
}

export type RefreshKenticoData = (key?: KenticoDataKeys) => Promise<void>;

export const KenticoDataContext = React.createContext<{
  assets: IKenticoData['assets'];
  callCenter: IKenticoData['callCenter'];
  featureFlags: IKenticoData['featureFlags'];
  refreshData: RefreshKenticoData;
}>({
  assets: {} as any,
  callCenter: {} as any,
  featureFlags: {} as any,
  refreshData: async () => {},
});

export const refreshCallCenter = async () => {
  const closures = await getCallCenterClosureConfig(
    dayjs().add(-1, 'd').toDate(),
  );
  const remoteConfig = await getSimpleRemoteConfig();

  // call center hours of operation
  const hoursOfOperation = parseJSONOrDefault(
    remoteConfig['call_center_hours_of_operation'],
    defaults.callCenterHoursOfOperation,
  );

  return { closures, hoursOfOperation };
};

/**
 * Provides data from Kentico, allows for refreshes
 * @param p Props
 * @returns React Context Provider
 */
export const KenticoDataContextProvider = (p: {
  children: any;
  assets: IKenticoData['assets'];
  callCenter: IKenticoData['callCenter'];
  featureFlags: IKenticoData['featureFlags'];
}) => {
  const [assets, setAssets] = React.useState<IKenticoData['assets']>(p.assets);
  const [callCenter, setCallCenter] = React.useState<
    IKenticoData['callCenter']
  >(p.callCenter);
  const [featureFlags, setFeatureFlags] = React.useState<
    IKenticoData['featureFlags']
  >(p.featureFlags);

  /**
   * Refresh data from Kentico
   * @param key Optional
   */
  const refreshData = async (key?: KenticoDataKeys) => {
    switch (key) {
      case KenticoDataKeys.assets:
        setAssets(await getAllAssetReferences());
        break;
      case KenticoDataKeys.callCenter:
        setCallCenter(await refreshCallCenter());
        break;
      case KenticoDataKeys.featureFlags:
        setFeatureFlags(await getFeatureFlags());
        break;
      default:
        setAssets(await getAllAssetReferences());
        setCallCenter(await refreshCallCenter());
        setFeatureFlags(await getFeatureFlags());
    }
  };

  React.useEffect(() => {
    p.assets && setAssets(p.assets);
    p.callCenter && setCallCenter(p.callCenter);
    p.featureFlags && setFeatureFlags(p.featureFlags);
  }, [p]);

  return (
    <KenticoDataContext.Provider
      value={{
        assets,
        callCenter,
        featureFlags,
        refreshData,
      }}
    >
      {p.children}
    </KenticoDataContext.Provider>
  );
};
