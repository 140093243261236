import React from 'react';
import * as sc from './index.sc';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import { enums, parseJSONOrDefault } from '../../../utils';
import { getSimpleRemoteConfig } from '../../../utils/remote-config-manager';
import styled from 'styled-components';
import { ILandingProps, ISplashContent } from '../landing';
import { RouteComponentProps } from 'react-router';

const ar = enums.AssetReferences;

const Container = styled.div`
  height: 90%;
  margin: 40px;

  @media (min-width: 376px) and (max-width: 834px) {
    margin: 60px;
  }

  @media (min-width: 835px) {
    margin: 52px 140px 52px 140px;
  }
`;

function Splash(props: RouteComponentProps & ILandingProps) {
  const { location, content } = props;
  const c: ISplashContent = parseJSONOrDefault(content, {});
  const isAlreadyRegistered =
    location?.search === enums.AppSearch.alreadyRegistered;

  const { assets } = React.useContext(KenticoDataContext);
  const [config, setConfig] = React.useState<any>({});

  React.useEffect(() => {
    const run = async () => {
      setConfig(await getSimpleRemoteConfig());
    };

    run();
  }, []);

  return (
    <Container className="d-flex flex-column justify-content-between align-items-center">
      <img
        id="unum-logo"
        className="align-self-start"
        src={assets[ar.landingUnumLogo].url}
        alt=""
      />
      <div className="d-flex flex-column-reverse flex-lg-row">
        <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <sc.Title className="text-center text-lg-left">{c.header}</sc.Title>
          <sc.Paragraph className="text-center text-lg-left">
            {isAlreadyRegistered ? c.paragraphAlreadyRegistered : c.paragraph}
          </sc.Paragraph>
          {!isAlreadyRegistered && (
            <sc.LoginButton
              className="mt-4"
              href={config['registration_redirect_url']}
              rel="noopener"
              style={{ whiteSpace: 'nowrap' }}
            >
              Register for an account
            </sc.LoginButton>
          )}
        </div>
        <img src={assets[ar.outage].url} alt="" className="mb-4 mw-100" />
      </div>
      <div className="align-self-start">
        <CopyrightContainer
          className="ml-0"
          main
          imageId="copyright-image"
          textId="copyright-text"
          text={`© ${new Date().getFullYear()} Unum Group. All rights reserved. Insurance products are underwritten by the subsidiaries of Unum Group. NS-580009`}
          desktopImage={assets[ar.landingUnumIcon].url}
          mobileImage={assets[ar.landingUnumIconWhite].url}
        />
      </div>
    </Container>
  );
}

export function CopyrightContainer(props: any) {
  return (
    <sc.CopyRightContainer {...props}>
      <sc.CopyRightImage id={props.imageId} {...props} />
      <sc.Paragraph small id={props.textId} {...props}>
        {props.text}
      </sc.Paragraph>
    </sc.CopyRightContainer>
  );
}

export default Splash;
