import React from 'react';
import ReactDOM from 'react-dom';
import './style/root.scss';
import AppStartupHandler from './components/base/app-startup-handler';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

if (process.env.NODE_ENV !== 'production') {
  window.LOG_LEVEL = 'DEBUG';
}

Object.defineProperty(global, 'connectSession', {
  value: null,
  writable: true,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <AppStartupHandler />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
