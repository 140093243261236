import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class CallCenterClosure extends ContentItem {
  public end!: Elements.DateTimeElement;
  public flags!: Elements.MultipleChoiceElement;
  public ivrMessage!: Elements.TextElement;
  public start!: Elements.DateTimeElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'ivr_message') {
          return 'ivrMessage';
        }
        return elementName;
      },
    });
  }
}
