import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, zIndices } from '../../../../style';
import {
  CenteredContentContainer,
  FullScreenHeader,
  FullScreenSubHeader,
  MaxWidthWrapper,
} from '../../simple-styled-components';
import { LogoArea } from '../../app-header/logo-area';
import { prefixObjectValues } from '../../../../utils';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  messageHeader: 'exception-screen-message-header',
  messageImage: 'exception-screen-message-image',
  messageSubHeader: 'exception-screen-message-sub-header',
  rootNode: 'exception-screen-root-node',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  background-color: ${colors.aquaHaze};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndices.modal};
`;

const ContentContainer = styled(CenteredContentContainer)`
  padding-bottom: 35px;
`;

const HeaderWrapper = styled(MaxWidthWrapper)`
  align-items: center;
  display: flex;
  min-height: 70px;
`;

const Image = styled.img.attrs({ alt: '' })`
  height: 350px;
  width: 350px;
`;

/*******************************************************************************
 * ExceptionScreen component
 * @param {ExceptionScreen.propTypes} props ExceptionScreen propTypes
 ******************************************************************************/
const ExceptionScreen = (props) => {
  const domIds = domIdsUnique(props.keyId);

  return (
    <Container className="fade-in-quick" id={domIds.rootNode}>
      <HeaderWrapper>
        <LogoArea />
      </HeaderWrapper>
      <ContentContainer>
        <Image id={domIds.messageImage} src={props.messageImageUrl} />
        <FullScreenHeader id={domIds.messageHeader}>
          {props.messageHeader}
        </FullScreenHeader>
        <FullScreenSubHeader id={domIds.messageSubHeader}>
          {props.messageSubHeader}
        </FullScreenSubHeader>
        {props.children}
      </ContentContainer>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
ExceptionScreen.propTypes = {
  messageHeader: PropTypes.string.isRequired,
  messageImageUrl: PropTypes.string.isRequired,
  messageSubHeader: PropTypes.string.isRequired,
};

ExceptionScreen.defaultProps = {};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { ExceptionScreen, domIdsUnique };
