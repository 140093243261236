import {
  getAllAssetReferences,
  getFeatureFlags,
  getSimpleRemoteConfig,
  getSplashConfig,
} from '../remote-config-manager';
import { Logger } from 'aws-amplify';
import { enums, referrerIsOdyssei } from '..';
import {
  IKenticoData,
  refreshCallCenter,
} from '../../contexts/kentico-data-context';

/*******************************************************************************
 * startup-manager.js is a collection of functions to assist with the startup
 * routine of the app (splash screen config, authentication, remote config, etc)
 ******************************************************************************/

const log = new Logger('startup-manager');

export interface StartUpSequenceResult {
  state: string;
  context: {
    kentico: IKenticoData;
    remoteConfig?: {};
    splashConfig?: { showSplash: boolean; content?: string };
    handleLoginResult?: boolean;
    featureFlags?: {};
  };
  error?: unknown;
}

/**
 * @typedef {object} StartupContext
 * @property {SplashConfig} splashConfig
 * @property {boolean} handleLoginResult
 * @property {object} remoteConfig
 */

/**
 * Executes the startup sequence and returns an object that indicates the state
 * along with any contextual data related to that state
 * @returns {{state:string, context:StartupContext}}
 */
export const startUpSequence = async () => {
  const returnObj: StartUpSequenceResult = {
    state: 'INIT',
    context: { kentico: {} as any },
  };

  // get remote config
  try {
    returnObj.state = enums.AppStates.GET_REMOTE_CONFIG;
    returnObj.context.remoteConfig = await getSimpleRemoteConfig();
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.GET_REMOTE_CONFIG_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  // check referrer, redirect as necessary
  if (await referrerIsOdyssei()) {
    returnObj.state = enums.AppStates.REDIRECT_TO_APP_LOGIN;
    return returnObj;
  }

  // get splash config first
  try {
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG;
    returnObj.context.splashConfig = await getSplashConfig();
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  // get other kentico items
  try {
    returnObj.state = enums.AppStates.GET_KENTICO_DATA;
    const results = await Promise.all([
      await getAllAssetReferences(),
      await refreshCallCenter(),
      await getFeatureFlags(),
    ]);
    returnObj.context.kentico.assets = results[0];
    returnObj.context.kentico.callCenter = results[1];
    returnObj.context.kentico.featureFlags = results[2];
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.GET_KENTICO_DATA_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  if (returnObj.context.splashConfig.showSplash) {
    returnObj.state = enums.AppStates.SHOW_SPLASH;
    return returnObj;
  }

  // if we get to here, all the checks completed successfully
  returnObj.state = enums.AppStates.COMPLETE;
  return returnObj;
};
