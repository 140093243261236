import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class FormButton extends ContentItem {
  public buttonType!: Elements.MultipleChoiceElement;
  public optionsTemplate?: Elements.TextElement;
  public label!: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'button_type') {
          return 'buttonType';
        }
        if (elementName === 'options_template') {
          return 'optionsTemplate';
        }
        return elementName;
      },
    });
  }
}
