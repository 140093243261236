/* eslint-disable no-unused-vars */

/**
 * enums for the fields tat go into a claim submission
 */
export enum AnswerFieldName {
  leaveScenario = 'leaveScenario',
  eventDate = 'eventDate',
  planType = 'planType',
  employeeType = 'employeeType',
  healthInsuranceProvider = 'healthInsuranceProvider',
  reason = 'reason',
  reasonQualifier1 = 'reasonQualifier1',
  reasonQualifier2 = 'reasonQualifier2',
  primaryFamilyMemberRelationship = 'primaryFamilyMemberRelationship',
  primaryFamilyMemberRelationshipQualifier1 = 'primaryFamilyMemberRelationshipQualifier1',
  primaryFamilyMemberRelationshipQualifier2 = 'primaryFamilyMemberRelationshipQualifier2',
  workRelated = 'workRelated',
  claimType = 'claimType',
  dateSymptomsFirstAppeared = 'dateSymptomsFirstAppeared',
  expectedReturnToWorkDate = 'expectedReturnToWorkDate',
  employerDateLastWorked = 'employerDateLastWorked',
  dismembermentOrLoss = 'dismembermentOrLoss',
  insuredSpouseWorking = 'insuredSpouseWorking',
  salaryCountNumDays = 'salaryCountNumDays',
  actualDeliveryMethod = 'actualDeliveryMethod',
  additionalComments = 'additionalComments',
}
/**
 * Paths for external API's
 */
export enum ApiPaths {
  // V2 API
  episodicAbsences = '/episodicAbsences',
  callback = '/callback',
  cases = '/cases',
  chat = '/chat',
  disablity = '/disablity',
  document = '/document',
  documents = '/documents',
  draftClaim = '/draftClaim',
  draftClaims = '/draftClaims',
  esignature = '/esignature',
  getPaymentPreferences = '/getPaymentPreferences',
  getZelleEnrollmentsStatus = '/getZelleEnrollmentsStatus',
  hospitalization = '/hospitalization',
  ldw = '/ldw',
  leaveScenarios = '/leaveScenarios',
  person = '/person',
  personData = '/personData',
  preDraft = '/preDraft',
  preferences = '/preferences',
  pregnancy = '/pregnancy',
  refresh = '/refresh',
  setPaymentPreferences = '/setPaymentPreferences',
  submission = '/submission',
  tasks = '/tasks',
  webmessage = '/webmessage',
  task = '/task',
  ada = '/accommodation',
}

/**
 * Internal URL paths within the app
 */

export enum AppPaths {
  root = '/',
  appLoginDirective = '/app?login=true',
  appRoot = '/app',
  contactUs = '/contact-us',
  page = '/page/',
  loading = '/loading',
}

export enum AppSearch {
  alreadyRegistered = '?a',
}

export enum AppStates {
  GET_KENTICO_DATA = 'GET_KENTICO_DATA',
  GET_KENTICO_DATA_ERROR = 'GET_KENTICO_DATA_ERROR',
  GET_SPLASH_CONFIG = 'GET_SPLASH_CONFIG',
  GET_SPLASH_CONFIG_ERROR = 'GET_SPLASH_CONFIG_ERROR',
  SHOW_SPLASH = 'SHOW_SPLASH',
  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_REGISTERABLE = 'NOT_REGISTERABLE',
  GET_REMOTE_CONFIG = 'GET_REMOTE_CONFIG',
  GET_REMOTE_CONFIG_ERROR = 'GET_REMOTE_CONFIG_ERROR',
  GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS',
  GET_FEATURE_FLAGS_ERROR = 'GET_FEATURE_FLAGS_ERROR',
  COMPLETE = 'COMPLETE',
  REDIRECT_TO_APP_LOGIN = 'REDIRECT_TO_APP_LOGIN',
}

export enum AssetReferences {
  landingUnumLogo = 'landing-unum-logo.png',
  landingCheckmark = 'landing-checkmark.png',
  landingScreen = 'landing-screen.png',
  landingUnumIconWhite = 'landing-unum-icon-white.png',
  landingUnumIcon = 'landing-unum-icon.png',
  outage = 'outage.svg',
  informationIcon = 'information-icon.png',
}

export enum ButtonTypes {
  breadcrumb = 'breadcrumb',
  primary = 'primary',
  secondary = 'secondary',
  secondaryRed = 'secondaryRed',
  flat = 'flat',
  link = 'link',
}

/**
 * Keys for accessing objects in the cache
 */
export enum CacheKeys {
  activelogin = 'activelogin',
  assetReferences = 'asset_references',
  auth_state = 'auth_state',
  callCenterClosures = 'callCenterClosures',
  cognitoid = 'cognitoid',
  dismissedOutageWarnings = 'dismissed_outage_warnings',
  entryPoint = 'entryPoint',
  form_config = 'form_config',
  lastLoginTime = 'lastLoginTime',
  remote_config = 'remote_config',
  sessionCredentials = 'sessionCredentials',
  splash_config = 'splash_config',
  ssoCookieKey = 'ssoCookieKey',
  ssoCookieValue = 'ssoCookieValue',
  userInfo = 'userInfo',
  featureFlags = 'feature_flags',
  dynamicContent = 'dynamic_content',
}

export enum CaseStatus {
  closed = 'Closed',
  open = 'Open',
}

export enum CodeNames {
  allAssets = 'all_assets',
  refProjectId = 'kontent_project_id',
  refPreviewKey = 'kontent_preview_key',
  refPreviewFlag = 'kontent_preview_flags',
}

export enum CredentialPath {
  accessKeyId = 'config.credentials.accessKeyId',
  secretAccessKey = 'config.credentials.secretAccessKey',
  sessionToken = 'config.credentials.sessionToken',
}

export enum DataSources {
  availableCallCenterTimesBasedOn = '$availableCallCenterTimesBasedOn',
  callCenterClosures = '$callCenterClosures',
  formField = '$formField',
  formContext = '$formContext',
  toDate = '$toDate',
  weekends = '$weekends',
}

export enum EventTypes {
  navBarMount = 'navBarMount',
  navBarUnmount = 'navBarUnmount',
  navBarChange = 'navBarChange',
}

export enum FontNames {
  openSans = 'openSans',
  ubuntu = 'ubuntu',
}

export enum FontTypes {
  light = 'light',
  regular = 'regular',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
}

export enum FormFieldTypes {
  checkbox = 'Checkbox',
  datepicker = 'DatePicker',
  dropdownlist = 'DropDownList',
  input = 'Input',
  leaveplangraph = 'LeavePlanGraph',
  maskedtextbox = 'MaskedTextBox',
  signer = 'Signer',
  switch = 'Switch',
  textarea = 'TextArea',
  timepicker = 'TimePicker',
  weeklyschedule = 'WeeklySchedule',
  claimsdropdownlist = 'ClaimsDropDownList',
}

export enum FormFlags {
  allDay = 'all_day',
  includeCallCenterClosureContext = 'include_call_center_closure_context',
  plainLabel = 'plain_label',
  required = 'required',
  card = 'card',
  showSteps = 'show_steps',
}

export enum FormSectionTypes {
  FormButton = 'form_button',
  FormField = 'form_field',
  FormText = 'form_text',
}

export enum HubChannels {
  datePicker = 'date-picker',
  formContext = 'form-ontext',
  navBarButtonChannel = 'use-nav-bar-button',
  personDataContext = 'person-data-context',
  userInfo = 'user-info',
}

export enum HubEvents {
  append = 'append',
  init = 'init',
  refresh = 'refresh',
  refreshed = 'refreshed',
  set = 'set',
}

export enum KenticoDataKeys {
  assets = 'assets',
  callCenter = 'callCenter',
  featureFlags = 'featureFlags',
}

export enum LeaveTypes {
  personalMedical = 'personalMedical',
  pregnancy = 'pregnancy',
}

export enum PaymentPrefsIds {
  Accounting = 66236000,
  DirectDeposit = 66236001,
  InternalAdjustment = 66236003,
  Check = 66236007,
  AdviceToPay = 66239010,
  Zelle = 66239997,
}

export enum RefreshStatus {
  failed = 'Failed',
  failedWithData = 'Failed with data',
  refreshed = 'refreshed',
  started = 'started',
  notRegisterable = 'Not registerable',
}

/**
 * keys for accessing items in the remote config
 */
export enum RemoteConfigKeys {
  awsSessionCheckIntervalMs = 'aws_session_check_interval_ms',
  awsSessionDurationHours = 'aws_session_duration_hours',
  dynamicStyleEndpoint = 'dynamic_style_endpoint',
  uploadLimit = 'upload_file_size_limit',
  refreshCheckCount = 'refresh_check_count',
  refreshCheckIntervalMs = 'refresh_check_interval_ms',
  odyessiUrl = 'odyssei_preferences_url',
  showPaymentPrefs = 'show_payment_preferences_on_profile',
  hidePrefsForId = 'hide_payment_preferences_on_profile_for_employer_i',
  documentTitles = 'document_titles',
}

export type ToastTypes = {
  style: 'none' | 'success' | 'error';
  icon: boolean;
};

/**
 * Messages for field validations on the forms
 */
export enum ValidationErrors {
  hasContent = 'Please fill out this required field',
  signerHasContent = 'Please ensure that you have signed and filled out the required fields',
  weeklyScheduleHasContent = 'Please ensure all time values are valid',
  noAlpha = 'No letters are allowed in this field',
  noNumeric = 'No number are allowed in this field',
  noSpecial = 'No special characters are allowed in this field',
  onlyAlpha = 'Only letters are allowed in this field',
  onlyNumberic = 'Only numbers are allowed in this field',
  matchesPattern = 'Please check this value again',
  dateGreaterThan = 'Please provide a date greater than the minimum',
  dateLesserThan = 'Please provide a date lesser than the maximum',
  dateGreaterThanOrEqual = 'Please provide a date greater than the minimum',
  dateLesserThanOrEqual = 'Please provide a date lesser than the maximum',
}

export enum UploadStatus {
  allSuccessful = 'all_successful',
  someErrors = 'some_errors',
  allErrors = 'all_errors',
}
