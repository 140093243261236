import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { FormField } from './form_field';
import { FormText } from './form_text';
import { FormButton } from './form_button';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class FormStep extends ContentItem {
  public label?: Elements.TextElement;
  public icon?: Elements.AssetsElement;
  public sections!: Elements.LinkedItemsElement<
    FormField | FormText | FormButton
  >;
}
