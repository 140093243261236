import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { prefixObjectValues } from '../../../../utils';

import unumTotalLeaveLogo from '../../../../images/unum-total-leave-logo.svg';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'logo-area-root-node',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  margin-right: auto;
`;

const Image = styled.img.attrs({
  alt: '',
  src: unumTotalLeaveLogo,
})`
  @media (max-width: 400px) {
    transform: scale(0.9, 0.9);
  }
`;

const LinkMod = styled(Link)`
  align-items: center;
  display: flex;

  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

/*******************************************************************************
 * LogoArea component
 * @param {LogoArea.propTypes} props LogoArea propTypes
 ******************************************************************************/
const LogoArea = (props) => {
  const domIds = domIdsUnique(props.keyId);

  return (
    <Container id={domIds.rootNode}>
      <LinkMod to="/app/">
        <Image />
      </LinkMod>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
LogoArea.propTypes = {
  /** The text used between the image and text */
  separatorText: PropTypes.string,
  /** The text to display with the image */
  text: PropTypes.string,
};

LogoArea.defaultProps = {
  separatorText: '|',
  text: 'Total Leave',
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { LogoArea, domIdsUnique };
