import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { get, prefixObjectValues } from '../../../utils';
import { useEffect, useRef, useState } from 'react';

const buttonSize = 50;

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'action-button-root-node',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const ActionIcon = styled.img.attrs({ alt: '' })``;

const Container = styled.button`
  border-style: none;
  border-radius: ${buttonSize / 2}px;
  height: ${buttonSize}px;
  overflow: hidden;
  position: relative;
  transition: width 0.5s;
  width: ${buttonSize}px;

  &:hover {
    width: ${(props) => props.expandedWidth};
  }
`;

const FloatingText = styled.div`
  background-color: ${colors.skyD2};
  border-radius: ${buttonSize / 2}px;
  color: ${colors.coolNeutralL1};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(16)};
  height: ${buttonSize}px;
  line-height: ${rem(22)};
  left: 0;
  padding: 14px 20px 14px 60px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
`;

const IconBackground = styled.div`
  align-items: center;
  background-color: ${colors.skyD2};
  border-radius: ${buttonSize / 2}px;
  display: flex;
  height: ${buttonSize}px;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  transition: background-color 1s;
  width: ${buttonSize}px;

  ${Container}:hover & {
    background-color: ${colors.blue};
  }
`;

/*******************************************************************************
 * Returns an object that mimics an html element with getBoundingClientRect
 * function that returns the provided width
 * @param {*} w The width to return
 ******************************************************************************/
const fallbackWidth = (w) => ({ getBoundingClientRect: () => ({ width: w }) });

/*******************************************************************************
 * ActionButton component
 * @param {ActionButton.propTypes} props ActionButton propTypes
 ******************************************************************************/
const ActionButton = (props) => {
  const { imageUrl, keyId, text, ...others } = props;
  const domIds = domIdsUnique(keyId);

  const textRef = useRef(null);
  const [expandedWidth, setExpandedWidth] = useState();

  // The code below executes after the component initially renders. The empty
  // dependency list causes this code to only execute once after the initial
  // render. When it does execute, it retrieves the width of the FloatingText
  // component, which is the full width of the expanded button. The expanded
  // width is set to the retrieved width so that the hover animation works as
  // expected. In the event that the width isn't available, an appropriate
  // fallback width can be set.
  useEffect(() => {
    const safeRef = get(textRef, 'current', fallbackWidth(buttonSize));
    setExpandedWidth(`${safeRef.getBoundingClientRect().width}px`);
  }, []);

  return (
    <Container id={domIds.rootNode} expandedWidth={expandedWidth} {...others}>
      <FloatingText ref={textRef}>{text}</FloatingText>
      <IconBackground>
        <ActionIcon src={imageUrl} />
      </IconBackground>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
ActionButton.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ActionButton.defaultProps = {};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { ActionButton, domIdsUnique };
