import dayjs from 'dayjs';
import React from 'react';
import { Reveal } from '@progress/kendo-react-animation';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import { enums, parseJSONOrDefault } from '../../../utils';
import {
  getSimpleRemoteConfig,
  getSplashConfig,
  hoursInFuture,
} from '../../../utils/remote-config-manager';
import * as sc from './index.sc';
import { localCache } from '../../../utils/app-cache';
import { Logger } from '@aws-amplify/core';
import Button from '../button';

const log = new Logger('landing');
const ar = enums.AssetReferences;

const templateIds = {
  logo: 'landing-unum-logo',
  title: 'landing-main-title',
  subtext: 'landing-main-subtext',
  registrationButton: 'landing-registration-button',
  link: 'landing-login-button',
  copyrightIconId: 'landing-copyright-image',
  copyrightTextId: 'landing-copyright-text',
  desktopImage: 'landing-desktop-image',
  mobileImage: 'landing-mobile-image',
  secondaryTitle: 'landing-secondary-title',
  secondarySubtext: 'landing-secondary-subtext',
  list: 'landing-list-container',
  spanishInfoIcon: 'spanish-info-icon',
};

export interface ILandingProps {
  showSplash?: boolean;
  content?: string;
}

export interface IWarning {
  message: string;
  hoursBefore: number;
  canDismiss?: boolean;
  rememberDismiss?: boolean;
  backgroundColor?: string;
  nextStartDateTime?: Date;
  stickyTop?: boolean;
}

export interface ISplashContent {
  header: string;
  paragraph: string;
  paragraphAlreadyRegistered: string;
  warnings?: [IWarning];
}

export const isOutageDismissed = (
  startDateTime?: Date,
  hoursBefore?: number,
) => {
  try {
    const json = localCache.getItem(enums.CacheKeys.dismissedOutageWarnings);
    const list = parseJSONOrDefault(json, []);
    return list.some(
      (o: string) => o === `${startDateTime?.toISOString()}_${hoursBefore}`,
    );
  } catch (e) {
    log.error('Unable to check if dismissed', { startDateTime });
  }

  return false;
};

export const dismissOutageLocal = (
  startDateTime?: Date,
  hoursBefore?: number,
) => {
  if (!startDateTime) {
    return;
  }

  try {
    const json = localCache.getItem(enums.CacheKeys.dismissedOutageWarnings);
    const list = parseJSONOrDefault(json, []);
    list.push(`${startDateTime.toISOString()}_${hoursBefore}`);
    localCache.setItem(
      enums.CacheKeys.dismissedOutageWarnings,
      JSON.stringify(list),
      {
        expires: hoursInFuture(hoursBefore || 1),
      },
    );
  } catch (e) {
    log.error('Unable to dismiss warning', e);
  }
};

function Landing(_props: ILandingProps) {
  const [showWarning, setShowWarning] = React.useState(false);
  const [warning, setWarning] = React.useState<IWarning>();
  const [config, setConfig] = React.useState<any>({});
  const [showSpanish, setShowSpanish] = React.useState<boolean>(false);
  const { assets } = React.useContext(KenticoDataContext);

  React.useEffect(() => {
    const run = async () => {
      const upcomingSplash = await getSplashConfig(true);

      if (upcomingSplash.showSplash) {
        const content: ISplashContent = parseJSONOrDefault(
          upcomingSplash.content,
        );

        if ((content?.warnings?.length || 0) > 0) {
          // grab the first warning in the list that matches the current hours
          // before the outage

          const applicableWarning = content?.warnings?.find((w) => {
            const startWarningTime = dayjs(
              upcomingSplash.nextStartDateTime,
            ).add(-w.hoursBefore, 'hour');

            return startWarningTime.isBefore(dayjs());
          });

          if (applicableWarning) {
            const isDismissed = isOutageDismissed(
              upcomingSplash.nextStartDateTime,
              applicableWarning.hoursBefore,
            );
            if (!isDismissed) {
              setWarning({
                ...applicableWarning,
                nextStartDateTime: upcomingSplash.nextStartDateTime,
              });
              setShowWarning(true);
            }
          }
        }
      }

      setConfig(await getSimpleRemoteConfig());
    };

    run();
  }, []);

  const dismissWarning = React.useCallback(() => {
    setShowWarning(false);
    if (warning?.rememberDismiss) {
      dismissOutageLocal(warning?.nextStartDateTime, warning?.hoursBefore);
    }
  }, [
    warning?.hoursBefore,
    warning?.nextStartDateTime,
    warning?.rememberDismiss,
  ]);

  return (
    <div className="d-flex flex-column h-100">
      <Reveal
        style={{ display: 'block' }}
        className={warning?.stickyTop ? 'sticky-top' : ''}
      >
        {showWarning && (
          <sc.Banner backgroundColor={warning?.backgroundColor || ''}>
            <span
              className="k-icon k-i-warning align-self-center mr-2"
              style={{ fontSize: '24px' }}
            ></span>
            <sc.BannerText>{warning?.message}</sc.BannerText>
            {warning?.canDismiss && (
              <div
                style={{ position: 'absolute', right: '10px', height: '100%' }}
              >
                <Button
                  style={{ color: 'white', height: '100%', width: '40px' }}
                  onClick={dismissWarning}
                  buttonStyle={enums.ButtonTypes.flat}
                >
                  <span
                    style={{ color: 'white' }}
                    className="k-icon k-i-close"
                  ></span>
                </Button>
              </div>
            )}
          </sc.Banner>
        )}
      </Reveal>
      <sc.Row>
        <sc.WhiteStrip style={{ backgroundColor: '#F6F8FA' }}>
          <sc.MainContentContainer>
            <sc.LogoContainer>
              <img
                id={templateIds.logo}
                src={assets[ar.landingUnumLogo].url}
                alt=""
              />
            </sc.LogoContainer>
            <sc.MainTextContainer>
              <sc.Title id={templateIds.title} main>
                Welcome to Unum Total Leave
              </sc.Title>
              <sc.Paragraph id={templateIds.subtext}>
                Our Portal is a simple and easy way to plan leave, receive
                benefits and smoothly navigate your return to work.
              </sc.Paragraph>
              <sc.VerticalAlignedRow>
                <sc.CustomButton
                  id={templateIds.registrationButton}
                  href={config['registration_redirect_url']}
                  rel="noopener"
                >
                  Register for an account
                </sc.CustomButton>
                <sc.LoginButton
                  id={templateIds.link}
                  href={config['login_redirect_url']}
                  rel="noopener"
                >
                  Login to existing account
                </sc.LoginButton>
              </sc.VerticalAlignedRow>
              <sc.SpanishContainer>
                <sc.SpanishLinkContainer>
                  <img
                    id={templateIds.spanishInfoIcon}
                    src={assets[ar.informationIcon].url}
                    alt=""
                    style={{ marginRight: '8px' }}
                  />
                  Quiere presentar o actualizar un reclamo en español?{' '}
                  <sc.SpanishShowButton
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowSpanish(!showSpanish)}
                    onKeyDown={() => setShowSpanish(!showSpanish)}
                  >
                    {showSpanish ? 'Ver menos' : 'Ver más'}
                  </sc.SpanishShowButton>
                </sc.SpanishLinkContainer>
                {showSpanish && (
                  <sc.SpanishInfoContainer>
                    Si necesita asistancia en español para empezar un nuevo
                    reclamo, puede contactarnos llamando a 866-868-6737, de
                    lunes a viernes entre 8am y 8pm ET
                  </sc.SpanishInfoContainer>
                )}
              </sc.SpanishContainer>
            </sc.MainTextContainer>
            <CopyrightContainer
              main
              imageId={templateIds.copyrightIconId}
              textId={templateIds.copyrightTextId}
              text={`© ${new Date().getFullYear()} Unum Group. All rights reserved. Insurance products are underwritten by the subsidiaries of Unum Group. NS-580009`}
              desktopImage={assets[ar.landingUnumIcon].url}
              mobileImage={assets[ar.landingUnumIconWhite].url}
            />
          </sc.MainContentContainer>
          <sc.ImageContainer>
            <img
              id={templateIds.desktopImage}
              src={assets[ar.landingScreen].url}
              alt=""
            />
          </sc.ImageContainer>
        </sc.WhiteStrip>
        <sc.BlueStrip style={{ backgroundColor: '#004470' }}>
          <sc.SecondaryTextContainer>
            <sc.SecondaryTitle id={templateIds.secondaryTitle}>
              What can you do?
            </sc.SecondaryTitle>
            <sc.Paragraph whiteContrast id={templateIds.secondarySubtext}>
              It's a centralized place to ask questions, file a claim and get
              support when you need it.
            </sc.Paragraph>
            <sc.ListContainer id={templateIds.list}>
              <div>
                <sc.ListItem>
                  <img src={assets[ar.landingCheckmark].url} alt="" />
                  <sc.Paragraph whiteContrast>
                    Simple ways to upload information
                  </sc.Paragraph>
                </sc.ListItem>
                <sc.ListItem>
                  <img src={assets[ar.landingCheckmark].url} alt="" />
                  <sc.Paragraph whiteContrast>Faster payments</sc.Paragraph>
                </sc.ListItem>
              </div>
              <div>
                <sc.ListItem>
                  <img src={assets[ar.landingCheckmark].url} alt="" />
                  <sc.Paragraph whiteContrast>
                    Notifications the way you want
                  </sc.Paragraph>
                </sc.ListItem>
                <sc.ListItem>
                  <img src={assets[ar.landingCheckmark].url} alt="" />
                  <sc.Paragraph whiteContrast>
                    Quick answers and live support
                  </sc.Paragraph>
                </sc.ListItem>
              </div>
            </sc.ListContainer>
          </sc.SecondaryTextContainer>
          <sc.MobileImage
            id={templateIds.mobileImage}
            src={assets[ar.landingScreen].url}
            alt=""
          />
          <CopyrightContainer
            imageId={templateIds.copyrightIconId}
            textId={templateIds.copyrightTextId}
            text={`© ${new Date().getFullYear()} Unum Group. All rights reserved. Insurance products are underwritten by the subsidiaries of Unum Group. NS-580009`}
            desktopImage={assets[ar.landingUnumIcon].url}
            mobileImage={assets[ar.landingUnumIconWhite].url}
          />
        </sc.BlueStrip>
      </sc.Row>
    </div>
  );
}

export function CopyrightContainer(props: any) {
  return (
    <sc.CopyRightContainer {...props}>
      <sc.CopyRightImage id={props.imageId} {...props} />
      <sc.Paragraph small id={props.textId} {...props}>
        {props.text}
      </sc.Paragraph>
    </sc.CopyRightContainer>
  );
}

export default Landing;
