import { Hub, HubCapsule } from '@aws-amplify/core';
import React from 'react';
import useHubListener from '../../hooks/use-hub-listener';
import {
  enums,
  generateParentNTNsFromCases,
  setAnalyticsData,
} from '../../utils';
import { getPersonData } from '../../utils/web-apis-client';
import * as dm from '../../data-models';

const PersonDataContext = React.createContext<{
  personData: dm.PersonData | undefined;
  setPersonData: React.Dispatch<React.SetStateAction<any>>;
}>({ personData: undefined, setPersonData: () => {} });

function PersonDataProvider(p: { children: any; personData?: dm.PersonData }) {
  const enhancePersonData = (personData?: dm.PersonData) => {
    if (!personData) {
      return personData;
    }

    const enhancedPersonData: dm.IGeneratedPersonData = { ...personData };

    enhancedPersonData.generated = enhancedPersonData.generated
      ? enhancedPersonData.generated
      : {};

    enhancedPersonData.generated.eid =
      enhancedPersonData.person && enhancedPersonData.person.eid
        ? enhancedPersonData.person.eid
        : '';

    enhancedPersonData.generated.parentNTNs = generateParentNTNsFromCases(
      enhancedPersonData.cases,
    );

    try {
      setAnalyticsData(enhancedPersonData);
    } catch (ex) {}

    return enhancedPersonData;
  };

  const [personData, setPersonData] = React.useState<dm.PersonData | undefined>(
    enhancePersonData(p.personData),
  );

  const handleHubAction = async (e: HubCapsule) => {
    const data = e.payload;

    if (data?.event === enums.HubEvents.refresh) {
      const pd = await getPersonData();
      setPersonData(pd);

      // send a message in case consumers need to know when the refresh is done
      Hub.dispatch(enums.HubChannels.personDataContext, {
        event: enums.HubEvents.refreshed,
      });
    }
  };

  useHubListener(enums.HubChannels.personDataContext, handleHubAction);

  return (
    <PersonDataContext.Provider value={{ personData, setPersonData }}>
      {p.children}
    </PersonDataContext.Provider>
  );
}

export { PersonDataContext, PersonDataProvider };
