import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class SimpleRemoteConfigurationItem extends ContentItem {
  public development?: Elements.TextElement;
  public local?: Elements.TextElement;
  public user_acceptance?: Elements.TextElement;
  public acceptance2?: Elements.TextElement;
  public stress?: Elements.TextElement;
  public production?: Elements.TextElement;
  public development2?: Elements.TextElement;
  public acceptance?: Elements.TextElement;
  public pfix?: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'acceptance_2') {
          return 'acceptance2';
        }
        if (elementName === 'development_2') {
          return 'development2';
        }
        return elementName;
      },
    });
  }
}
