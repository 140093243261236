import React, { useState } from 'react';

/**
 * A hook to assist with executing an asynchronous function
 */
const useAsyncHandler = <D, R = D>(
  func: () => Promise<D>,
  // eslint-disable-next-line no-unused-vars
  transformResult?: (result?: D) => R,
  delay?: number,
  checkResult?: (result: D | R) => boolean,
  initialData?: R,
  maxRetries = 0,
  retryIntervalMs = 2000,
) => {
  const [begin, setBegin] = useState(false);
  const [data, setData] = useState<D | R | undefined>(initialData);
  const [initData] = useState(initialData);
  const [delayedNotify, setDelayedNotify] = useState(false);
  const [error, setError] = useState<any>();
  const [isBusy, setBusy] = useState(false);
  const [retries, setRetries] = useState(0);

  function startRequest() {
    setBegin(true);
  }

  function reset() {
    setBusy(false);
    setData(initData);
    setDelayedNotify(false);
    setError(undefined);
    setBegin(false);
  }

  const tryWork = React.useCallback(async () => {
    let intendToRetry = false;
    try {
      const result = await func();
      let finalResult: D | R = result;

      if (transformResult) {
        finalResult = transformResult(result);
      }

      // 1. either set it if we've gotten this far, or check it if we were passed a checker
      // 2. if #1 fails, the checker failed and we need to check first if we're over the maxRetries
      // 3. if #2 fails, we know the checker failed and we are no over the maxRetries, so retry
      // or throw a general error
      if (!checkResult || (checkResult && !!checkResult(finalResult))) {
        setData(finalResult);
      } else if (retries >= maxRetries) {
        setRetries(0);
        setBusy(false);
        throw Error('Too many retries');
      } else if (maxRetries > 0) {
        setRetries((r) => r + 1);
        intendToRetry = true;
      } else {
        throw Error('Result not acceptable, no retries attempted');
      }

      if (delay) {
        setTimeout(() => setDelayedNotify(true), delay);
      }
    } catch (err) {
      setError(err);
    } finally {
      // only return to a non-busy state while we're not retrying
      if (!intendToRetry) {
        setBusy(false);
      }
    }
  }, [checkResult, delay, func, maxRetries, retries, transformResult]);

  const doWork = React.useCallback(async () => {
    if (begin) {
      setBusy(true);
      tryWork();
    }
  }, [begin, tryWork]);

  React.useEffect(() => {
    doWork();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begin]);

  React.useEffect(() => {
    if (retries > 0) {
      setTimeout(() => {
        doWork();
      }, retryIntervalMs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries]);

  return {
    startRequest,
    data,
    delayedNotify,
    error,
    isBusy,
    reset,
    started: begin,
    setError,
    retries,
  };
};

export default useAsyncHandler;
