import {
  startUpSequence,
  StartUpSequenceResult,
} from '../../../utils/startup-manager';
import useAsyncHandler from '../../../hooks/use-async-handler';
import { enums } from '../../../utils';
import App from '../app';
import { ErrorScreen } from '../exception-screens/error-screen';
import { LoadingScreen } from '../loading-screen';
import { MemoryRouter } from 'react-router-dom';
import { KenticoDataContextProvider } from '../../../contexts/kentico-data-context';

/*******************************************************************************
 * AppStartupHandler component
 * @returns A view based on the start up sequence
 ******************************************************************************/
const AppStartupHandler = () => {
  const { data, delayedNotify, error, started, startRequest } =
    useAsyncHandler<StartUpSequenceResult>(startUpSequence, undefined, 250);

  if (!started) {
    startRequest();
  }

  if (error) {
    return (
      <MemoryRouter>
        <ErrorScreen />
      </MemoryRouter>
    );
  }

  if (data && delayedNotify) {
    if (data.state === enums.AppStates.REDIRECT_TO_APP_LOGIN) {
      window.location.assign(enums.AppPaths.appLoginDirective);
      return <LoadingScreen />;
    }

    switch (data.state) {
      case enums.AppStates.GET_REMOTE_CONFIG_ERROR:
      case enums.AppStates.GET_SPLASH_CONFIG_ERROR:
      case enums.AppStates.GET_KENTICO_DATA_ERROR:
        return (
          <MemoryRouter>
            <ErrorScreen />
          </MemoryRouter>
        );

      case enums.AppStates.AUTHENTICATING:
        return <LoadingScreen />;

      default:
        return (
          <KenticoDataContextProvider
            assets={data.context.kentico.assets}
            callCenter={data.context.kentico.callCenter}
            featureFlags={data.context.kentico.featureFlags}
          >
            <App landingProps={data.context.splashConfig} />
          </KenticoDataContextProvider>
        );
    }
  }

  return <LoadingScreen />;
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export default AppStartupHandler;
