
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class FeatureFlags extends ContentItem {
    public production!: Elements.MultipleChoiceElement;
    public user_acceptance!: Elements.MultipleChoiceElement;
    public local!: Elements.MultipleChoiceElement;
    public development!: Elements.MultipleChoiceElement;
    public acceptance!: Elements.MultipleChoiceElement;
}
