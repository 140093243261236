import envConfig from './env-config';
import kenticoConfig from '../kentico-config.json';
import * as models from '../kontent-models';
import { TypeResolver, DeliveryClient } from '@kentico/kontent-delivery';
import { enums } from '.';

/*******************************************************************************
 * cms-client.js creates / exports an instance of the Kentico DeliveryClient
 * to use throughout the application to interact with the Kentico Delivery API
 ******************************************************************************/

const referenceCmsClient = new DeliveryClient({
  projectId: kenticoConfig.referenceProjectId,
  typeResolvers: [
    new TypeResolver(
      'simple_remote_configuration_item',
      () => new models.SimpleRemoteConfigurationItem(),
    ),
  ],
});

const getReferenceValues = async () => {
  const envCodename = envConfig.upEnvironment.toLowerCase().replace('.', '_');
  const result = await referenceCmsClient
    .items<models.SimpleRemoteConfigurationItem>()
    .type('simple_remote_configuration_item')
    .elementsParameter([envCodename])
    .toPromise();

  const refItems: { [name: string]: any } = {};

  for (const item of result.items) {
    const codename = item.system.codename;
    refItems[codename] = item[envCodename].value;
  }

  return refItems;
};

let cmsClient: DeliveryClient;

const getClient = async () => {
  if (cmsClient) {
    return cmsClient;
  }

  const refItems = await getReferenceValues();

  cmsClient = new DeliveryClient({
    projectId: refItems[enums.CodeNames.refProjectId],
    previewApiKey: refItems[enums.CodeNames.refPreviewKey],
    globalQueryConfig: {
      usePreviewMode:
        !envConfig.isProd &&
        refItems[enums.CodeNames.refPreviewFlag] === 'true',
    },
    typeResolvers: [
      new TypeResolver('form', () => new models.Form()),
      new TypeResolver('form_step', () => new models.FormStep()),
      new TypeResolver('form_field', () => new models.FormField()),
      new TypeResolver('form_button', () => new models.FormButton()),
      new TypeResolver('form_text', () => new models.FormText()),
      new TypeResolver(
        'simple_remote_configuration_item',
        () => new models.SimpleRemoteConfigurationItem(),
      ),
      new TypeResolver('splash_config', () => new models.SplashConfig()),
      new TypeResolver('feature_flags', () => new models.FeatureFlags()),
      new TypeResolver('content_blob', () => new models.ContentBlob()),
      new TypeResolver('asset_container', () => new models.AssetContainer()),
    ],
  });

  return cmsClient;
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { getClient, models };
