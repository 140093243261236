import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../style';
import { prefixObjectValues } from '../../../utils';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'spinner-root-node',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  position: relative;
  width: 68px;
  height: 68px;
`;

const CircularLoader = styled.svg`
  animation: rotate 2s linear infinite;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  transform-origin: center center;
  width: 100%;

  > circle {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

/*******************************************************************************
 * Spinner component
 * @param {Spinner.propTypes} props Spinner propTypes
 ******************************************************************************/
const Spinner = (props) => {
  const domIds = domIdsUnique(props.keyId);

  return (
    <Container id={domIds.rootNode}>
      <CircularLoader viewBox="25 25 50 50">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={colors.blue}
          strokeWidth="5"
        />
      </CircularLoader>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
Spinner.propTypes = {};

Spinner.defaultProps = {};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { Spinner, domIdsUnique };
