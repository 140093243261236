import React from 'react';
import { colors } from '../../../style';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { enums, prefixObjectValues } from '../../../utils';
import Page from '../../page';
import { getSimpleRemoteConfig } from '../../../utils/remote-config-manager';
import Landing, { ILandingProps } from '../landing';
import Splash from '../splash';

interface IAppProps {
  keyId?: string;
  initialLiveChatSetting?: boolean;
  notRegisterable?: boolean;
  failedWithData?: boolean;
  landingProps?: ILandingProps;
}

export interface AppRoute {
  breadcrumb: string;
  path: string;
}

interface PortalContainerProps {
  livechat: boolean;
}

const domIdsStatic = {
  rootNode: 'app-root-node',
};

const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.background};
  display: flex;
  flex-flow: column;
  flex-direction: row;
  height: 100vh;
  min-height: 100%;
  overflow-y: auto;
`;

const MainContainer = styled.main`
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
`;

const PortalContainer = styled.div<PortalContainerProps>`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  @media (max-width: 576px) {
    display: ${(props) => (props.livechat ? 'none' : 'flex')};
  }
`;

export const getDocumentTitle = async (pathname: string) => {
  const defaultTitle = 'Unum Total Leave';
  const remoteConfig = await getSimpleRemoteConfig();
  const documentTitleMapRaw =
    remoteConfig[enums.RemoteConfigKeys.documentTitles];
  try {
    const documentTitleMap = JSON.parse(documentTitleMapRaw);

    return documentTitleMap[pathname]
      ? documentTitleMap[pathname]
      : defaultTitle;
  } catch (e) {
    return defaultTitle;
  }
};

export const getDynamicStyleEndpoint = async (
  setDynamicStyleEndpoint: Function,
) => {
  const remoteConfig = await getSimpleRemoteConfig();
  setDynamicStyleEndpoint(
    remoteConfig[enums.RemoteConfigKeys.dynamicStyleEndpoint],
  );
};

const App: (p: IAppProps) => JSX.Element = (props) => {
  const p = enums.AppPaths;
  const domIds = domIdsUnique(props.keyId);
  const [dynamicStyleEndpoint, setDynamicStyleEndpoint] = React.useState('');
  const history = useHistory();

  React.useEffect(() => {
    getDynamicStyleEndpoint(setDynamicStyleEndpoint);
  }, []);

  React.useEffect(() => {
    const unlisten = history.listen(async (location) => {
      document.title = await getDocumentTitle(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <Container id={domIds.rootNode} className="fade-in-quick">
        {/* Style API endpoint for css classes for dynamic Kentico content */}
        <link href={dynamicStyleEndpoint} rel="stylesheet" />
        <PortalContainer id="app-portal-container" livechat={false}>
          <MainContainer>
            <Switch>
              <Route
                path={p.root}
                exact
                render={(lp: any) => {
                  if (props?.landingProps?.showSplash) {
                    return <Splash {...lp} {...props.landingProps} />;
                  }

                  return <Landing {...lp} {...props.landingProps} />;
                }}
              />
              <Route path={'/splash'} component={Splash} />
              <Route path={p.page} component={Page} />
            </Switch>
          </MainContainer>
        </PortalContainer>
      </Container>
    </>
  );
};

export default App;
